import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RoleService } from "../role.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { HomeService } from "src/app/home/home.service";
import { CasesService } from "src/app/cases/cases.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  username: any;
  password: any;
  userdata: any = {};
  retrievedObject: any = {};
  userID: any;

  ShowCase: any;
  ShowHome: any;
  ShowProduct: any;
  ShowShipments: any;
  ShowTodo: any;
  ShowDentists: any;
  ShowCompanies: any;
  ShowManagments: any;
  ShowManagmentsGoToInvoice: any;
  ShowManagmentsManageCountry: any;
  ShowManagmentsGroupMessaging: any;
  ShowManagmentsUserManagment: any;
  ShowReports: any;
  //Report submenu
  ShowQuickYearOverview: any;
  ShowExcelExportReports: any;
  ShowStatisticsReports: any;

  ShowOrderPickup: any;

  Delete_case: any;
  Add_New_Companies: any;
  Update_Companies: any;
  Delete_Companies: any;
  Company_Profile: any;

  Company_Profile_Buttons: any;
  Update_User: any;
  Print_Lable: any;
  Order_Online_Setup: any;
  Unifaun_Acc_Info: any;
  Shipment_Series: any;
  Edit_Series: any;
  Delete_Series: any;
  Company_Opening_Hours: any;
  Company_Door_Code: any;
  Company_Add_Existing_User: any;
  Company_Remove_User: any;
  Company_Set_As_Reference: any;

  //Shipment
  Add_Shipment: any;
  Update_Shipment: any;
  Delete_Shipment: any;
  Shipment_PrintDocument: any;
  Shipment_MarkAsDelivered: any;
  Shipment_ShowComplete: any;
  Shipment_PrepareToSend: any;
  Shipment_AddCase: any;
  Shipment_IssueUnifaunDelivery: any;
  Shipment_RemoveCase: any;
  Shipment_MoveToShipment: any;
  Shipment_ShowCompleteCases: any;
  //Products
  Products_AddNewProducts: any;
  Products_Update: any;
  Products_Delete: any;
  //Pricelist
  Pricelist: any;
  Pricelist_Get: any;
  Pricelist_New: any;
  Pricelist_Delete: any;
  Pricelist_Copy: any;
  Pricelist_Save_Change: any;
  //Cases
  Case_Add_New: any;
  Case_Update: any;
  Case_Delete: any;
  Case_MarkAsFinished: any;
  Cases_MarkAllAsFinished: any;
  Case_Show: any;
  Case_MoveToShipment: any;
  Case_UpdateConstruction: any;
  Add_Materials: any;
  Update_Materials: any;
  Case_PostMessage: any;
  Case_Invoice: any;
  //Dentist
  Add_NewDentist: any;
  Update_Dentist: any;
  Delete_Dentist: any;
  View_Dentist: any;
  Dentist_OpeningHours: any;
  Dentist_DoorCode: any;
  Dentist_AddExistingUser: any;
  Dentist_PrintLabel: any;
  Dentist_SetAsRef: any;
  Dentist_UpdateUser: any;
  Dentist_RemoveUser: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private roleService: RoleService,
    private toastr: ToastrService,
    private homeService: HomeService,
    private caseService: CasesService
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
  }
  ngOnInit() {}

  onSubmit() {
    //this.spinner.show();
    let object = {
      Username: this.username,
      Password: this.password,
    };
    this.spinner.show();

    this.roleService.userLogin(object).subscribe(
      (data: any) => {
        if (data == "00") {
          this.toastr.error("Invalid User");
          this.username = "";
          this.password = "";
          this.spinner.hide();
        } else if (data == "11") {
          // this.getData()

          this.roleService.getUserDetailsByUsername(this.username).subscribe(
            (data: any) => {
              this.userdata = data[0];

              // storing the UserDetails object into localStorage as a string data
              localStorage.setItem(
                "UserDetails",
                JSON.stringify(this.userdata)
              );
              var retrievedObject = localStorage.getItem("UserDetails");

              // to read the perticular value from the object again am converting that string into objcet
              var jsObj = JSON.parse(retrievedObject);

              // here am storing the username into local Storage as 'username'
              // localStorage.getItem(jsObj.teethRUsUserId);
              localStorage.setItem("username", jsObj.username);
              localStorage.setItem("userid", jsObj.teethRUsUserId);
              localStorage.setItem("FileName", jsObj.photoURL);
              localStorage.setItem("UserEmail", jsObj.email);
              localStorage.setItem("CompanyEmail", jsObj.companyEmail);
              localStorage.setItem("CompanyId", jsObj.companyId);
              localStorage.setItem("CompanyName", jsObj.applicationName);
              localStorage.setItem(
                "UserFullName",
                jsObj.firstName + " " + jsObj.lastName
              );
              //newly add afetr chaning the SP
              localStorage.setItem("CountryId", jsObj.countryId);
              localStorage.setItem("DealerId", jsObj.dealerId);
              localStorage.setItem("DealerName", jsObj.dealerName);
              localStorage.setItem("ProdCompanyName", jsObj.companyName);
              localStorage.setItem("CurrencyCode", jsObj.currencyCode);
              localStorage.setItem("CountryCode", jsObj.countryCode);
              localStorage.setItem("DealerCountryId", jsObj.dealerCountryId);
              localStorage.setItem("IsSendEmail", jsObj.isSendSMS);
              localStorage.setItem("IsSendSMS", jsObj.isSendSMS);
              localStorage.setItem("SalesRepId", jsObj.salesRepId);
              this.userID = localStorage.getItem("userid");

              this.roleService.getRolePermissions(this.userID).subscribe(
                (data: any) => {
                  debugger
                  localStorage.setItem("RoleName", data[0].roleCode);
                  for (var i = 0; i < data.length; i++) {
                    // if(data[i].accessLevelCode == 'READ_ONLY' && data[i].systemFuncCode == 'COMPANIES_COMPANY_PROFILE') {
                    //   this.Company_Profile_Buttons  = 'false';
                    // }
                    //  if(data[i].accessLevelCode == 'READ_WRITE' && data[i].systemFuncCode == 'COMPANIES_COMPANY_PROFILE') {
                    //   this.Company_Profile_Buttons = 'true';
                    // }
                    if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_UPDATE_USER"
                    ) {
                      this.Update_User = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_PRINT_LABEL"
                    ) {
                      this.Print_Lable = "true";
                    } else if (
                      data[i].accessLevelCode === "READ_WRITE" &&
                      data[i].systemFuncCode === "COMPANIES_ORDER_ONLINE"
                    ) {
                      this.Order_Online_Setup = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_UNIFAUN_ACC_INFO"
                    ) {
                      this.Unifaun_Acc_Info = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_SHIPMENT"
                    ) {
                      this.Shipment_Series = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_EDIT_SERIES"
                    ) {
                      this.Edit_Series = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_REMOVE_SERIES"
                    ) {
                      this.Delete_Series = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_OPENING_HOURS"
                    ) {
                      this.Company_Opening_Hours = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_DOOR _CODE"
                    ) {
                      this.Company_Door_Code = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_ADD_EXISTING_USER"
                    ) {
                      this.Company_Add_Existing_User = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_REMOVE_USER"
                    ) {
                      this.Company_Remove_User = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_SET_AS_REFERENCE"
                    ) {
                      this.Company_Set_As_Reference = "true";
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "TODO_DELETE_CASE"
                    ) {
                      this.Delete_case = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_ADD_NEW"
                    ) {
                      this.Add_New_Companies = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_UPDATE"
                    ) {
                      this.Update_Companies = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_DELETE"
                    ) {
                      this.Delete_Companies = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "COMPANIES_COMPANY_PROFILE"
                    ) {
                      this.Company_Profile = true;
                    } else if (
                      data[i].accessLevelCode == "READ_ONLY" &&
                      data[i].systemFuncCode == "COMPANIES_COMPANY_PROFILE"
                    ) {
                      this.Company_Profile = true;
                    }
                    // Shipments
                    else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_ADD_NEW"
                    ) {
                      this.Add_Shipment = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_UPDATE"
                    ) {
                      this.Update_Shipment = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_DELETE"
                    ) {
                      this.Delete_Shipment = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_PRINT_DOCUMENTS"
                    ) {
                      this.Shipment_PrintDocument = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_MARK_AS_DELIVERED"
                    ) {
                      this.Shipment_MarkAsDelivered = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_SHOW_COMPLETE"
                    ) {
                      this.Shipment_ShowComplete = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_PREPARE_TO_SEND"
                    ) {
                      this.Shipment_PrepareToSend = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_ADD_CASE"
                    ) {
                      this.Shipment_AddCase = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode ==
                        "SHIPMENTS_ISSUE_UNIFAUN_DELIVERY"
                    ) {
                      this.Shipment_IssueUnifaunDelivery = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_REMOVE_CASE"
                    ) {
                      this.Shipment_RemoveCase = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_MOVE_TO_SHIPMENT"
                    ) {
                      this.Shipment_MoveToShipment = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "SHIPMENTS_SHOW_COMPLETE_CASE"
                    ) {
                      this.Shipment_ShowCompleteCases = true;
                    }
                    // PRODUCTS
                    else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRODUCTS_ADDPRODUCTS"
                    ) {
                      this.Products_AddNewProducts = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRODUCTS_UPDATE"
                    ) {
                      this.Products_Update = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRODUCTS_DELETE"
                    ) {
                      this.Products_Delete = true;
                    }
                    // PriceList
                    else if (
                      (data[i].accessLevelCode == "READ_WRITE" &&
                        data[i].systemFuncCode == "PRICELISTS") ||
                      (data[i].accessLevelCode == "READ_ONLY" &&
                        data[i].systemFuncCode == "PRICELISTS")
                    ) {
                      this.Pricelist = true;
                    } else if (
                      (data[i].accessLevelCode == "READ_WRITE" &&
                        data[i].systemFuncCode == "PRICELISTS_GET") ||
                      (data[i].accessLevelCode == "READ_ONLY" &&
                        data[i].systemFuncCode == "PRICELISTS_GET")
                    ) {
                      this.Pricelist_Get = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRICELISTS_NEW"
                    ) {
                      this.Pricelist_New = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRICELISTS_DELETE"
                    ) {
                      this.Pricelist_Delete = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRICELISTS_COPY"
                    ) {
                      this.Pricelist_Copy = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "PRICELISTS_SAVE_CHANGE"
                    ) {
                      this.Pricelist_Save_Change = true;
                    }
                    // Cases
                    else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_ADD_NEW"
                    ) {
                      this.Case_Add_New = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_UPDATE"
                    ) {
                      this.Case_Update = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_DELETE"
                    ) {
                      this.Case_Delete = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_MARK_AS_FINISHED"
                    ) {
                      this.Case_MarkAsFinished = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode ==
                        "CASES_MARK_RW_CHECKED_AS_FINISHED"
                    ) {
                      this.Cases_MarkAllAsFinished = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_SHOW_COMPLETE"
                    ) {
                      this.Case_Show = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_MOVE_TO_SHIPMENT"
                    ) {
                      this.Case_MoveToShipment = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_UPDATE_CONSTRUCTION"
                    ) {
                      this.Case_UpdateConstruction = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_ADD_MATERIALS"
                    ) {
                      this.Add_Materials = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_UPDATE_MATERIALS"
                    ) {
                      this.Update_Materials = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_POST_MESSAGE"
                    ) {
                      this.Case_PostMessage = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "CASES_INVOICE"
                    ) {
                      this.Case_Invoice = true;
                    }
                    //Dentist
                    else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_ADD_NEW"
                    ) {
                      this.Add_NewDentist = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_UPDATE"
                    ) {
                      this.Update_Dentist = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_DELETE"
                    ) {
                      this.Delete_Dentist = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_SHOW_PROFILE"
                    ) {
                      this.View_Dentist = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_OPENING_HOURS"
                    ) {
                      this.Dentist_OpeningHours = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_DOOR_CODE"
                    ) {
                      this.Dentist_DoorCode = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_ADD_EXISTING_USER"
                    ) {
                      this.Dentist_AddExistingUser = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_PRINT_LABEL"
                    ) {
                      this.Dentist_PrintLabel = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_SET_AS_REFERENCE"
                    ) {
                      this.Dentist_SetAsRef = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_UPDATE_USER"
                    ) {
                      this.Dentist_UpdateUser = true;
                    } else if (
                      data[i].accessLevelCode == "READ_WRITE" &&
                      data[i].systemFuncCode == "DENTISTS_REMOVE_USER"
                    ) {
                      this.Dentist_RemoveUser = true;
                    }

                    //     localStorage.setItem('CompanyProfileButtons',this.Company_Profile_Buttons);
                    localStorage.setItem("UpdateUser", this.Update_User);
                    localStorage.setItem("PrintLable", this.Print_Lable);
                    localStorage.setItem(
                      "OrderOnlineSetup",
                      this.Order_Online_Setup
                    );
                    localStorage.setItem(
                      "UnifaunAccInfo",
                      this.Unifaun_Acc_Info
                    );
                    localStorage.setItem(
                      "ShipmentSeries",
                      this.Shipment_Series
                    );
                    localStorage.setItem("EditSeries", this.Edit_Series);
                    localStorage.setItem("RemoveSeries", this.Delete_Series);
                    localStorage.setItem(
                      "CompanyOpeningHours",
                      this.Company_Opening_Hours
                    );
                    localStorage.setItem(
                      "CompanyDoorCode",
                      this.Company_Door_Code
                    );
                    localStorage.setItem(
                      "CompanyAddExistingUser",
                      this.Company_Add_Existing_User
                    );
                    localStorage.setItem(
                      "RemoveUser",
                      this.Company_Remove_User
                    );
                    localStorage.setItem(
                      "CompanySetAsReference",
                      this.Company_Set_As_Reference
                    );

                    localStorage.setItem("DeleteCase", this.Delete_case);
                    localStorage.setItem(
                      "AddNewCompanies",
                      this.Add_New_Companies
                    );
                    localStorage.setItem(
                      "UpdateCompanies",
                      this.Update_Companies
                    );
                    localStorage.setItem(
                      "DeleteCompanies",
                      this.Delete_Companies
                    );
                    localStorage.setItem(
                      "CompanyProfile",
                      this.Company_Profile
                    );

                    // Shipments
                    localStorage.setItem("AddShipment", this.Add_Shipment);
                    localStorage.setItem(
                      "UpdateShipment",
                      this.Update_Shipment
                    );
                    localStorage.setItem(
                      "DeleteShipment",
                      this.Delete_Shipment
                    );
                    localStorage.setItem(
                      "ShipmentPrintDocument",
                      this.Shipment_PrintDocument
                    );
                    localStorage.setItem(
                      "ShipmentMarkAsDelivered",
                      this.Shipment_MarkAsDelivered
                    );
                    localStorage.setItem(
                      "ShipmentShowComplete",
                      this.Shipment_ShowComplete
                    );
                    localStorage.setItem(
                      "ShipmentPrepareToSend",
                      this.Shipment_PrepareToSend
                    );
                    localStorage.setItem(
                      "ShipmentAddCase",
                      this.Shipment_AddCase
                    );
                    localStorage.setItem(
                      "ShipmentIssueUnifaunDelivery",
                      this.Shipment_IssueUnifaunDelivery
                    );
                    localStorage.setItem(
                      "ShipmentRemoveCase",
                      this.Shipment_RemoveCase
                    );
                    localStorage.setItem(
                      "ShipmentMoveToShipment",
                      this.Shipment_MoveToShipment
                    );
                    localStorage.setItem(
                      "ShipmentShowCompleteCases",
                      this.Shipment_ShowCompleteCases
                    );
                    //Products
                    localStorage.setItem(
                      "ProductsAddNewProducts",
                      this.Products_AddNewProducts
                    );
                    localStorage.setItem(
                      "ProductsUpdate",
                      this.Products_Update
                    );
                    localStorage.setItem(
                      "ProductsDelete",
                      this.Products_Delete
                    );
                    // PriceList
                    localStorage.setItem("Pricelist", this.Pricelist);
                    localStorage.setItem("PricelistGet", this.Pricelist_Get);
                    localStorage.setItem("PricelistNew", this.Pricelist_New);
                    localStorage.setItem(
                      "PricelistDelete",
                      this.Pricelist_Delete
                    );
                    localStorage.setItem("PricelistCopy", this.Pricelist_Copy);
                    localStorage.setItem(
                      "PricelistSaveChange",
                      this.Pricelist_Save_Change
                    );
                    //Cases
                    localStorage.setItem("AddNewCase", this.Case_Add_New);
                    localStorage.setItem("UpdateCase", this.Case_Update);
                    localStorage.setItem("DeleteCase", this.Case_Delete);
                    localStorage.setItem(
                      "CaseMarkAsFinished",
                      this.Case_MarkAsFinished
                    );
                    localStorage.setItem(
                      "CasesMarkAllAsFinished",
                      this.Cases_MarkAllAsFinished
                    );
                    localStorage.setItem("ShowCase", this.Case_Show);
                    localStorage.setItem(
                      "CasesMoveToShipment",
                      this.Case_MoveToShipment
                    );
                    localStorage.setItem(
                      "CaseUpdateConstruction",
                      this.Case_UpdateConstruction
                    );
                    localStorage.setItem("AddMaterials", this.Add_Materials);
                    localStorage.setItem(
                      "UpdateMaterials",
                      this.Update_Materials
                    );
                    localStorage.setItem(
                      "CasePostMessage",
                      this.Case_PostMessage
                    );
                    localStorage.setItem("CaseInvoice", this.Case_Invoice);
                    //Dentist
                    localStorage.setItem("AddNewDentist", this.Add_NewDentist);
                    localStorage.setItem("UpdateDentist", this.Update_Dentist);
                    localStorage.setItem("DeleteDentist", this.Delete_Dentist);
                    localStorage.setItem("ViewDentist", this.View_Dentist);
                    localStorage.setItem(
                      "DentistOpeningHours",
                      this.Dentist_OpeningHours
                    );
                    localStorage.setItem(
                      "DentistDoorCode",
                      this.Dentist_DoorCode
                    );
                    localStorage.setItem(
                      "DentistAddExistingUser",
                      this.Dentist_AddExistingUser
                    );
                    localStorage.setItem(
                      "DentistPrintLabel",
                      this.Dentist_PrintLabel
                    );
                    localStorage.setItem(
                      "DentistSetAsRef",
                      this.Dentist_SetAsRef
                    );
                    localStorage.setItem(
                      "DentistUpdateUser",
                      this.Dentist_UpdateUser
                    );
                    localStorage.setItem(
                      "DentistRemoveUser",
                      this.Dentist_RemoveUser
                    );
                  }

                  let var3: any = [];
                  data.forEach((element) => {
                    var3.push(element.menuCode);
                  });

                  for (var i = 0; i < var3.length; i++) {
                    if (var3[i] === "CASES") {
                      this.ShowCase = true;
                    } else if (var3[i] === "HOME") {
                      this.ShowHome = true;
                    } else if (var3[i] === "PRODUCTS") {
                      this.ShowProduct = true;
                    } else if (var3[i] === "SHIPMENTS") {
                      this.ShowShipments = true;
                    } else if (var3[i] === "TODO") {
                      this.ShowTodo = true;
                    } else if (var3[i] === "DENTISTS") {
                      this.ShowDentists = true;
                    } else if (var3[i] === "COMPANIES") {
                      this.ShowCompanies = true;
                    } else if (var3[i] === "MANAGMENTS") {
                      this.ShowManagments = true;
                    } else if (var3[i] === "GOTOINVOICE") {
                      this.ShowManagmentsGoToInvoice = true;
                    } else if (var3[i] === "MANAGE_COUNTRY") {
                      this.ShowManagmentsManageCountry = true;
                    } else if (var3[i] === "GROUP_MESSAGING") {
                      this.ShowManagmentsGroupMessaging = true;
                    } else if (var3[i] === "USER_MANAGEMENT") {
                      this.ShowManagmentsUserManagment = true;
                    } else if (var3[i] === "REPORTS") {
                      this.ShowReports = true;
                    }

                    //   Report SubMenu
                    else if (var3[i] === "QUICK_YEAR_OVERVIEW") {
                      this.ShowQuickYearOverview = true;
                    } else if (var3[i] === "EXCEL_EXPORT") {
                      this.ShowExcelExportReports = true;
                    } else if (var3[i] === "STATISTICS") {
                      this.ShowStatisticsReports = true;
                    } else if (var3[i] === "ORDER_PICKUP") {
                      this.ShowOrderPickup = true;
                    }
                  }
                  localStorage.setItem("ShowHome", this.ShowHome);
                  localStorage.setItem("ShowCase", this.ShowCase);
                  localStorage.setItem("ShowProduct", this.ShowProduct);
                  localStorage.setItem("ShowShipments", this.ShowShipments);
                  localStorage.setItem("ShowTodo", this.ShowTodo);
                  localStorage.setItem("ShowDentists", this.ShowDentists);
                  localStorage.setItem("ShowCompanies", this.ShowCompanies);
                  localStorage.setItem("ShowManagments", this.ShowManagments);

                  localStorage.setItem(
                    "ShowManagmentsGoToInvoice",
                    this.ShowManagmentsGoToInvoice
                  );
                  localStorage.setItem(
                    "ShowManagmentsManageCountry",
                    this.ShowManagmentsManageCountry
                  );
                  localStorage.setItem(
                    "ShowManagmentsGroupMessaging",
                    this.ShowManagmentsGroupMessaging
                  );
                  localStorage.setItem(
                    "ShowManagmentsUserManagment",
                    this.ShowManagmentsUserManagment
                  );
                  localStorage.setItem("ShowReports", this.ShowReports);
                  //   Report SubMenu
                  localStorage.setItem(
                    "ShowQuickYearOverview",
                    this.ShowQuickYearOverview
                  );
                  localStorage.setItem(
                    "ShowExcelExportReports",
                    this.ShowExcelExportReports
                  );
                  localStorage.setItem(
                    "ShowStatisticsReports",
                    this.ShowStatisticsReports
                  );
                  localStorage.setItem("ShowOrderPickup", this.ShowOrderPickup);
                  //this.spinner.hide();
                  if (data[0].roleCode === "DEALER_SALESREP") {
                    this.router.navigate(["/salesrepdashboard"]);
                  } else {
                    this.router.navigate(["/denthome"]);
                  }
                  //this.password = "";
                  localStorage.setItem("loginStatus", "true");
                },
                (err) => {
                  this.toastr.error(err);
                  this.spinner.hide();
                }
              );
            },
            (err) => {
              this.toastr.error(err);
              this.spinner.hide();
            }
          );
        } else if (data == "22") {
          this.toastr.warning("User Is Already Locked");
          this.username = "";
          this.password = "";
          this.spinner.hide();
        } else if (data == "33") {
          this.toastr.warning("Locked");
          this.spinner.hide();
        } else if (data == "44") {
          this.toastr.info(
            "This user is blocked. Contact Administration to unblock."
          );
          this.spinner.hide();
        } else {
          this.toastr.warning(
            "You have only" + "  " + data + "  " + "   Attempts Remaining"
          );
          this.password = "";
          this.spinner.hide();
        }
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  }
}
