import { Injectable } from '@angular/core';
import { Companies } from './companies.model';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from '../app.config';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable()

export class CompaniesService {
  private url = "";
  private baseUrl = "";
  PhotoUrl:any
  userID:any;
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
    this.PhotoUrl=this.baseUrl+'Uploads/DealersLogo'
    this.userID = localStorage.getItem('userid');
  }
  
  // handleError(error) {
  //   // let errorMessage = '';
  //   // if (error.error instanceof ErrorEvent) {
  //   //   // client-side error
  //   //   errorMessage = `Error: ${error.error.message}`;
  //   // } else {
  //   //   // server-side error
  //   //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   // }
  //   //window.alert(error.error.Message);
  //   //this.toastr.error(errorMessage);
  //   return throwError(error.error.Message);
  // }

  GetCompanies(id: any,companySearchId): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetCompanies?companyTypeId=' + id +'&companySearchId='+companySearchId;    
    return this.http.get<any[]>(this.url);
  }
  GetCompanies1(obj): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetCompanies1';    
    return this.http.post(this.url,obj);
  }

  Addcompany(data): Observable<any> {
    this.url = this.baseUrl + 'api/company/'
    return this.http.post<any[]>(this.url, data);

  }
  Updatecompany(id, data): Observable<any> {
    this.url = this.baseUrl + 'api/company/Update';
    return this.http.post<any[]>(this.url, data);

  }
  GetCompanyTypes(): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetCompanyTypes'
    return this.http.get<any[]>(this.url);
  }
  GetCompanyDetail(id: any): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetCompanyDetail?companyId=' + id;
    return this.http.get<any[]>(this.url);
  }
  GetCountries() : Observable<any>{
    this.url = this.baseUrl + 'api/company/GetCountries'
    return this.http.get<any[]>(this.url);
  }
  GetPriceList(id:any): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetPriceList?DealerId='+id;
    return this.http.get<any[]>(this.url);
  }

  TrashCompany(object): Observable<any> {
    this.url = this.baseUrl + 'api/Company/DeleteCompany';
    return this.http.post(this.url,object);
  }

  UpdateCompanyHours(data): Observable<any> {
    this.url = this.baseUrl + 'api/Company/UpdateCompanyOpenHours';
    return this.http.post<any[]>(this.url, data);
  }

  UpdateCompanyDoorCode(companyId, code) : Observable<any>{
    this.url = this.baseUrl + 'api/Company/UpdateCompanyDoorCode?companyId=' + companyId + '&doorCode=' + code;
    return this.http.get<any[]>(this.url);
  }

  GetOwners() : Observable<any>{
    this.url = this.baseUrl + 'api/Company/GetOwners';
    return this.http.get<any[]>(this.url);
  }
  GetUsers(companyId) : Observable<any>{
    this.url = this.baseUrl + 'api/Company/GetUsers?companyId='+companyId;
    return this.http.get<any[]>(this.url);
  }
  AddUserToCompany(companyId, userId) : Observable<any>{
    this.url = this.baseUrl + 'api/Company/AssignUserToCompany';
    return this.http.post<any[]>(this.url, { companyId: companyId, userId: userId });
  }
  SetAsCompanyRef(companyId, userId): Observable<any> {
    this.url = this.baseUrl + 'api/Company/SetAsContactReference';
    return this.http.post<any[]>(this.url, { companyId: companyId, userId: userId });
  }
  GetDealers(): Observable<any> {
    this.url = this.baseUrl + 'api/Company/GetDealers';
    return this.http.get<any[]>(this.url);
  }
  GetDeliveryServices(): Observable<any> {
    this.url = this.baseUrl + 'api/Company/GetDeliveryServices';
    return this.http.get<any[]>(this.url);
  }
  deleteDentAppUser(object) : Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/DeleteDentAppUser';
    return this.http.post(this.url,object);
  }
  GetProductionsForSeries(dealerId): Observable<any> {
    this.url = this.baseUrl + 'api/Company/GetProductionsForSeries?dealerId='+dealerId;
    return this.http.get<any[]>(this.url);
  }
  GetProductions(): Observable<any> {
    this.url = this.baseUrl + 'api/Company/GetProductions';
    return this.http.get<any[]>(this.url);
  }

  CreateShipmentSeries(pId, series, eta, companyId) : Observable<any>{
    this.url = this.baseUrl + 'api/Company/CreateShipmentSeries';
    return this.http.post<any[]>(this.url, { ProductionId: pId, ShipmentSeriesNo: series, EtaDays: eta, DealerId: companyId });
  }

  UpdateShipmentSeries(series:any): Observable<any> {
    this.url = this.baseUrl + 'api/Company/CreateShipmentSeries';
    return this.http.post<any[]>(this.url, series);
  }

  GetShipmentSeries(id): Observable<any> {
    this.url = this.baseUrl + 'api/Company/GetShipmentSeries?dealerId=' + id;
    return this.http.get<any[]>(this.url);
  }
  GetInvoiceAgents() : Observable<any>{
    this.url = this.baseUrl + 'api/Company/GetInvoiceAgents';
    return this.http.get<any[]>(this.url);
  }
  DeleteShipmentSeries(seriesId) : Observable<any>{
    this.url = this.baseUrl + 'api/Company/DeleteShipmentSeries?shipmentSeriesId='+seriesId;
    return this.http.get<any[]>(this.url);
  }

  GetProductionForDealer(id) : Observable<any>{
    this.url = this.baseUrl + 'api/Company/GetProductionForDealer?dealerId=' + id;
    return this.http.get<any[]>(this.url);
  }

  getCompaniesByUserId(id: any) : Observable<any>{
    this.url = this.baseUrl + 'api/company/GetCompaniesByUserId?TeethRUsUserId=' + id;
    return this.http.get<any[]>(this.url);
  }
  getCompaniesByUserId1(compniesObj) : Observable<any>{
    this.url = this.baseUrl + 'api/company/GetCompaniesByUserId1';
    // return this.http.get<any[]>(this.url);
    return this.http.post(this.url,compniesObj);
  }
  getDentistsByUserId(id: any): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetDentistsByUserId?TeethRUsUserId=' + id;
    return this.http.get<any[]>(this.url);
  }
  getDentistsByUserId1(compniesObj): Observable<any> {
    this.url = this.baseUrl + 'api/company/GetDentistsByUserId1';
    // return this.http.get<any[]>(this.url);
    return this.http.post(this.url,compniesObj);
  }

  GetStageConfigs(id){
    this.url = this.baseUrl + 'api/company/GetStageConfigs?productionCompanyId=' + id;
    return this.http.get<any[]>(this.url);
  }

  SaveStageConfigs(stages:any):Observable<any> {
    this.url = this.baseUrl + 'api/company/SaveStageConfigs';
    return this.http.post<any[]>(this.url,stages);
  }

  createMessageLog(obj) {

    this.url = this.baseUrl + 'api/AppUser/SendSms';
    return this.http.post(this.url, obj);

  }
  addOnlineWelcomeText(object): Observable<any> {

    this.url = this.baseUrl + 'api/company/AddOnlineWelcomeText';
    return this.http.post(this.url,object);
  }
  getMessageLogInfo(object):Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/GetMessageLogInfo';
    return this.http.post(this.url,object);
  }
  getActiveSalesRepresentative():Observable<any>{
    this.url=this.baseUrl + 'api/Management/GetActiveSalesRepresentative';
    return this.http.get(this.url);
  }

  ConvertDentistToLead(company): Observable<any> {
    this.url = this.baseUrl + 'api/company/ConvertDentistToLead';
    return this.http.post(this.url, company);
  }

}
