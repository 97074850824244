import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NgxSpinnerService } from "ngx-spinner";
import { ManageLeadsService } from "../manage-leads.service";
import { forkJoin } from "rxjs";
import { ManagementService } from "src/app/management/management.service";
import { CompaniesService } from "src/app/companies/companies.service";
import { CompanyTypeId, SalesRepStatus } from "../../../enums";
import { formatDate } from "@angular/common";
import { ReminderDate } from "../../../enum/statusEnum";

@Component({
  selector: "app-grid-layout-leads",
  templateUrl: "./grid-layout-leads.component.html",
  styleUrls: ["./grid-layout-leads.component.css"],
})
export class GridLayoutLeadsComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('template') template: TemplateRef<any>;
  toLeadToCustomer:boolean = false;
  fromLeadToCustomer:boolean = false;
  toLeadToLost: boolean = false;
  eventContainer: CdkDragDrop<any[]>;
  isHidden = false;
  stagesName: any = [];
  stagesCount: any = [];
  Cases: any;
  Fulldata: any = [];
  id: any;
  url: any;
  roleName;
  arrow: boolean;
  stageID: any;
  collespoedata: any = [];
  Fulldatas1: any = [];

  stagesName1: any = [];
  stagesCount1: any = [];
  caseses1: any = [];

  New_Lead_Array: any = [];
  Cold_Lead_Array: any = [];
  Warm_Lead_Array: any = [];
  Hot_Lead_Array: any = [];
  Lead_To_Customer_Array: any = [];
  Lost_Leads_Array: any = [];

  New_Lead: any = 0;
  Cold_Lead: any = 0;
  Warm_Lead: any = 0;
  Hot_Lead: any = 0;
  Lead_To_Customer: any = 0;
  Lost_Leads: any = 0;

  New_Lead_Show: boolean = true;
  Cold_Lead_Show: boolean = true;
  Warm_Lead_Show: boolean = true;
  Hot_Lead_Show: boolean = true;
  Lead_To_Customer_Show: boolean = true;
  Lost_Leads_Show: boolean = true;
  // TransitInshow: boolean;
  // Deliveryshow: boolean;

  leadObject: any = {};

  // searchByDropdown: boolean = false;
  countryDD: boolean = false;
  // sortBy:any;
  // searchCaseBy:any;
  country: any = [];
  prod: any = [];
  DealerId: any = localStorage.getItem("DealerId");
  SalesRepId:any;
  userDetails = JSON.parse(localStorage.getItem("UserDetails"));
  CountryId: any = null;
  prodId: any = null;
  searchId: any = null;
  leads: any = [];
  type: any;
  ReminderDate: any;
  DateForReminder: any;
  reminder: boolean = false;
  ReminderDay = ReminderDate.reminderday;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private companyservice: CompaniesService,
    private manageLeadsService: ManageLeadsService,
    private modalService: BsModalService,
    private managementService: ManagementService
  ) {
    this.url = this.manageLeadsService.CountryPhotoUrl;
    this.roleName = localStorage.getItem("RoleName");
    let dt = new Date();
    dt.setDate( dt.getDate() + this.ReminderDay );
    this.ReminderDate = formatDate(dt,'yyyy-MM-dd','en_US');
    let dt1 = new Date();
    dt1.setDate( dt1.getDate() - this.ReminderDay );
    this.DateForReminder = formatDate(dt1,'yyyy-MM-dd','en_us');
  }

  ngOnInit() {
    //Scroll it to Top
    this.SalesRepId = this.userDetails.salesRepId;
    window.scrollTo(0, 0);
    this.countryFilter();
    this.getDashBoardData();
  }
  decline() {
    this.modalRef.hide();
  }
  confirmMovefromLeadtocustomer(){
    this.spinner.show();
    let object = {
      Id: this.leadObject.id,
      StatusId: this.eventContainer.container.id,
      UserId: localStorage.getItem("userid"),
      DentistId: this.leadObject.dentistId,
      ConversionDate: this.leadObject.conversionDate,
      isConvertedFromClient: this.leadObject.isConvertedFromClient,
    };
      this.movelead(this.eventContainer, object);
      const obj = {
        dentistId: this.leadObject.dentistId,
        isDelete: true,
      };
      this.manageLeadsService.updateCompany(obj).subscribe((data: any) => {
        this.toastr.success("Customer entry has been moved Successfully");
        this.modalRef.hide();
        this.spinner.hide();
      });
  }
  confirmMovetoLeadtocustomer(){
    this.spinner.show();
    if (!this.leadObject.dentistId) {
      const dentistObj = {
        CompanyTypeId: CompanyTypeId.Dentist,
        Street: this.leadObject.street,
        PostalCode: this.leadObject.postalCode,
        City: this.leadObject.city,
        Email: this.leadObject.email,
        PhoneNumber: this.leadObject.phoneNumber,
        CountryId: this.leadObject.countryId,
        RefCompanyId:this.leadObject.refCompanyId,
        SalesRepId: this.leadObject.salesRepId,
        CompanyNumber:this.leadObject.name,
        Name: this.leadObject.name,
      };
      this.companyservice.Addcompany(dentistObj).subscribe(
        (response: any) => {
          if (response) {
            // Update CRM Lead data from table
            const crmObj = {
              Id: this.leadObject.id,
              StatusId: this.eventContainer.container.id,
              DentistId: response,
              UserId: localStorage.getItem("userid"),
              ConversionDate: new Date(),
              isConvertedFromClient: true,
            };
            this.manageLeadsService
              .updateLeadStatus(crmObj)
              .subscribe((data: any) => {
                this.getDashBoardData();
                this.toastr.success(
                  "Customer entry has been created Successfully"
                );
                this.modalRef.hide();
                this.spinner.hide();
              });
          }
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    } else {
      // Update is delete company to true
      const crmObj = {
        Id: this.leadObject.id,
        StatusId: this.eventContainer.container.id,
        UserId: localStorage.getItem("userid"),
        DentistId: this.leadObject.dentistId,
        ConversionDate: new Date(),
        isConvertedFromClient: true,
      };
      this.movelead(this.eventContainer, crmObj);
      const obj = {
        dentistId: this.leadObject.dentistId,
        isDelete: false,
      };
      this.manageLeadsService.updateCompany(obj).subscribe((data: any) => {
        this.toastr.success("Customer entry has been created Successfully");
        this.modalRef.hide();
        this.spinner.hide();
      });
    }
  }
  confirmMovetoLeadtoLost(){
    this.spinner.show();
  let object = {
    Id: this.leadObject.id,
    StatusId: this.eventContainer.container.id,
    UserId: localStorage.getItem("userid"),
    DentistId: this.leadObject.dentistId,
    ConversionDate: this.leadObject.conversionDate,
    isConvertedFromClient: this.leadObject.isConvertedFromClient,
  };
// In Dentist Table make isDelete = true
if (this.leadObject.dentistId) {
  this.movelead(this.eventContainer, object);
  const obj = {
    dentistId: this.leadObject.dentistId,
    isDelete: true,
  };
  this.manageLeadsService.updateCompany(obj).subscribe((data: any) => {
    this.toastr.success("Customer entry has been deleted Successfully");
    this.modalRef.hide();
    this.spinner.hide();
  });
} else {
  this.movelead(this.eventContainer, object);
  this.modalRef.hide();
  this.spinner.hide();
}
  }
  drop(event: CdkDragDrop<any[]>) {  
    if (event.previousContainer === event.container) {
      event.previousContainer._draggables.reset;
    } else {
      this.spinner.show();
      this.toLeadToCustomer = false;
      this.fromLeadToCustomer = false;
      this.toLeadToLost = false;
      this.leadObject = "";
      this.eventContainer = event;
      let indexNumber = event.previousContainer.data.findIndex(
        (item) => item === event.item.data
      );
      this.leadObject = event.previousContainer.data[indexNumber];
      let object = {
        Id: this.leadObject.id,
        StatusId: event.container.id,
        UserId: localStorage.getItem("userid"),
        DentistId: this.leadObject.dentistId,
        ConversionDate: this.leadObject.conversionDate,
        isConvertedFromClient: this.leadObject.isConvertedFromClient,
      };
      if (event.container.id === "5") {      
        this.toLeadToCustomer = true;
        this.modalRef = this.modalService.show(this.template);
        this.spinner.hide();
      } else if (event.container.id === "6") {
        this.toLeadToLost = true;   
        this.modalRef = this.modalService.show(this.template); 
        this.spinner.hide();    
      } else {
        if(event.previousContainer.id === "5"){
          this.fromLeadToCustomer = true;
          this.modalRef = this.modalService.show(this.template);
          this.spinner.hide();
        } else{
          if (this.leadObject.dentistId) {
            this.movelead(this.eventContainer, object);
            const obj = {
              dentistId: this.leadObject.dentistId,
              isDelete: true,
            };
            this.manageLeadsService.updateCompany(obj).subscribe((data: any) => {
              this.toastr.success("Lead moved succesfully");
              this.modalRef.hide();
              this.spinner.hide();
            });
          } else {
            this.movelead(event, object);
            this.spinner.hide();
            this.toastr.success("Lead moved succesfully");
          }        
        }
      }
    }
  }

  movelead(event, object) {
    this.manageLeadsService.updateLeadStatus(object).subscribe((data: any) => {
      if (data === 1) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        this.getDashBoardData();
        //this.toastr.success("Lead Moved");
      } else {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        this.getDashBoardData();
      }
    });
  }
  getDashBoardData() {
    this.spinner.show();
    let object = {
      SalesRepId: this.SalesRepId,
      CountryId: this.CountryId,
      ProductionCompanyId: this.prodId,
    };
    this.leads = [];
    const leadsCount = this.manageLeadsService.getLeadsCountByStatus(
      this.SalesRepId
    );
    const leadsData = this.manageLeadsService.getLeadsBySalesRepId(object);
    forkJoin([leadsCount, leadsData]).subscribe((data: any) => {
      this.stagesCount = data[0];
      let gridLeads = data[1].crmleads;
      let gridLeadComments = data[1].leadsComment;
      gridLeads.forEach(ele => {
        if(gridLeadComments != null && gridLeadComments.length > 0){
          gridLeadComments.forEach(ele2 => {
            if(ele.id == ele2.leadId){
              if(ele2.isReminder == true && (this.ReminderDate >= formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') && formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') >= this.DateForReminder)){
               this.reminder = true;         
              } 
            }           
          });
        }
        if(this.reminder == true){
          ele.isActive = true;
          this.reminder = false;
        } else{
          ele.isActive = false;
        }
        this.leads.push(ele);
      });
      console.log("data 2334", this.leads);
      this.New_Lead_Array = [];
      this.Cold_Lead_Array = [];
      this.Warm_Lead_Array = [];
      this.Hot_Lead_Array = [];
      this.Lead_To_Customer_Array = [];
      this.Lost_Leads_Array = [];
      if(this.leads != null){
        this.leads.map((x) => {
          switch (x.status) {
            case SalesRepStatus.NewLead:
              this.New_Lead_Array.push(x);
              this.New_Lead_Array.sort((a, b) => (
                b.isActive - a.isActive
              ));     
              this.New_Lead_Show = true;
              break;
            case SalesRepStatus.ColdLead:
              this.Cold_Lead_Array.push(x);
              this.Cold_Lead_Array.sort((a, b) => (
                b.isActive - a.isActive
              ));     
              this.Cold_Lead_Show = true;
              break;
            case SalesRepStatus.WarmLead:
              this.Warm_Lead_Array.push(x);
              this.Warm_Lead_Array.sort((a, b) => (
                b.isActive - a.isActive
              ));     
              this.Warm_Lead_Show = true;
              break;
            case SalesRepStatus.HotLead:
              this.Hot_Lead_Array.push(x);
              this.Hot_Lead_Array.sort((a, b) => (
                b.isActive - a.isActive
              ));     
              this.Hot_Lead_Show = true;
              break;
            case SalesRepStatus.LeadtoCustomer:
              this.Lead_To_Customer_Array.push(x);
              this.Lead_To_Customer_Array.sort((a, b) => (
                b.isActive - a.isActive
              ));     
              this.Lead_To_Customer_Show = true;
              break;
            case SalesRepStatus.LostLeads:
              this.Lost_Leads_Array.push(x);
              this.Lost_Leads_Array.sort((a, b) => (
                b.isActive - a.isActive
              ));     
              this.Lost_Leads_Show = true;
              break;
            default:
              break;
          }
        });
      }

      this.spinner.hide();
    });
    this.spinner.hide();
  }

  closecollepes(stagedata1) {
    this.collespoedata = this.collespoedata.filter(
      (x) => x !== stagedata1.stageId
    );
  }
  showNewLead() {
    this.New_Lead = SalesRepStatus.NewLead;
  }
  hideNewLead() {
    this.New_Lead = 0;
    this.New_Lead_Show = true;
  }
  showColdLead() {
    this.Cold_Lead = SalesRepStatus.ColdLead;
  }
  hideColdLead() {
    this.Cold_Lead = 0;
    this.Cold_Lead_Show = true;
  }
  showWarmLead() {
    this.Warm_Lead = SalesRepStatus.WarmLead;
  }
  hideWarmLead() {
    this.Warm_Lead = 0;
    this.Warm_Lead_Show = true;
  }
  showHotLead() {
    this.Hot_Lead = SalesRepStatus.HotLead;
  }
  hideHotLead() {
    this.Hot_Lead = 0;
    this.Hot_Lead_Show = true;
  }
  showLeadToCustomer() {
    this.Lead_To_Customer = SalesRepStatus.LeadtoCustomer;
  }
  hideLeadToCustomer() {
    this.Lead_To_Customer = 0;
    this.Lead_To_Customer_Show = true;
  }
  showLostLead() {
    this.Lost_Leads = SalesRepStatus.LostLeads;
  }
  hideLostLead() {
    this.Lost_Leads = 0;
    this.Lost_Leads_Show = true;
  }

  countryFilter() {
    this.managementService.getAllCountry().subscribe((data: any) => {
      this.country = data;
    });
    this.countryDD = true;
  }

  searchByCountry($event, value) {
    this.getDashBoardData();
  }

  addCRMLeads() {
    this.router.navigate(["crm/newcrmlead"]);
  }

  openRecordNewTab(id) {
    this.type = "edit";
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/crm/updatecrmlead/' + this.type + "/" + id])
    );
    window.open(url, '_blank');
  }

}
