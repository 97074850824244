import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from '../reports/reports.service';
import { ManagementService } from 'src/app/management/management.service';
import { ExcelService } from '../share/excel.service';
import { ChartOptions,ChartType } from 'chart.js';
import { MessageService } from "../CRM/message/message.service";

@Component({
  selector: "app-sales-rep-dashboard",
  templateUrl: "./sales-rep-dashboard.component.html",
  styleUrls: ["../home/home.component.css"],
})
export class SalesRepDashboardComponent implements OnInit {
  DealerId:any;
  SalesRepId:any
  month:any;
  day:any;
  fromDate:any;
  toDate:any;
  startDate:any;
  startMonth:any = 2;
  startDay:any = 90;
  nintyfromDate:any;
  IcelandcountryId:any;
  country:any=[];
  customerList:any = [];
  dentistList:any=[];
  userDetails:any=[];
  RoleName:any;
  /*Box 4: Per month new lead showing charts*/ 
  ChartDatavalues: any = [];
  leadChartLabels: any = [];
  leadBarChartData: any = [
    {
      data: [], label:"Leads"
    }
  ]; 
  leadChartType: any = 'bar';
  pieLeadChartLegend:any=true;
  barLeadChartLegend:any=false;
  leadsType: ChartType = this.leadChartType;
  pieChartColors: Array<any> = [{
    backgroundColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)', '#003f5c', 'purple', 'green', 'red', '#98C227', 'orange', 'cyan', '#bc5090', '#58508d'],
    borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)', '#003f5c', 'purple', 'green', 'red', '#98C227', 'orange', 'cyan', '#bc5090', '#58508d']
  }];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  }
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };
  //crm messages box
  crmmessageList:any=[];
  UserId:any;
  constructor(public spinner: NgxSpinnerService,private toastr: ToastrService,private reportService: ReportsService,
    private managementService:ManagementService,private excelService: ExcelService,private messageService: MessageService) {}

  ngOnInit() {
    this.spinner.show();
    this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
    this.DealerId = localStorage.getItem("DealerId");
    this.SalesRepId=this.userDetails.salesRepId;
    this.RoleName = localStorage.getItem("RoleName");
    var currentyear = new Date().getFullYear().toString().substr(0);
    var currentmonth = (new Date().getMonth() + 1).toString().substr(0);
    var currentdate = new Date().getDate().toString().substr(0);
    if (currentmonth.length == 1) {
      currentmonth = 0 +currentmonth;
    }
    if (currentdate.length == 1) {
      currentdate = 0 + currentdate;
    }
    this.month = currentmonth;
    this.day = currentdate;
    this.toDate = currentyear + "-" + currentmonth + "-" + currentdate;    
    this.managementService.getAllCountry().subscribe((data: any) => {
      this.country = data;
      for(var i=0;i<this.country.length;i++){
      if(this.country[i].country=='Iceland') {
        this.IcelandcountryId=this.country[i].countryId;
        }
      }
    })        
    this.resolveAfter2Seconds(20).then(value => {
      this.GetCustomers();
      this.GetRevenueByLeadToCustomer();
      this.GetperMonthNewLeads();
      this.getCRMMessagesForDashboard();
      this.spinner.hide();
    });    
  }
  resolveAfter2Seconds(x) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, 2000);
    });
  }
  GetCustomers(){
    this.customerList = [];
    this.getFromDate("months",this.startMonth);
    let object = {
      'DealerCompanyId': this.DealerId,
      'FromDate': this.fromDate,
      'ToDate': this.toDate,
      'SalesRepId': this.SalesRepId,
      'CountryID': 0
    }    
    this.spinner.show();
    this.reportService.getCustomers(object).subscribe((data:any)=>{
    this.customerList = data;
    this.spinner.hide();
    }, (err) => { this.toastr.error(err); this.spinner.hide(); })  
  }
  GetRevenueByLeadToCustomer(){
    this.dentistList = [];
    this.getFromDate("days", this.startDay);
    let object = {
      'DealerCompanyId': this.DealerId,
      'FromDate': this.fromDate,
      'ToDate': this.toDate,
      'SalesRepId': this.SalesRepId,
      'CountryID': 0
    }
    this.reportService.getRevenueByLeadToCustomer(object).subscribe((data:any)=>{
    this.dentistList = data;
    this.dentistList.forEach(element => {
      if(element.countryId== this.IcelandcountryId) 
      {
        element.revenue=(element.revenue*0.07)
      }       
    });
    }, (err) => { this.toastr.error(err); this.spinner.hide(); })      
  }
  getFromDate(key, value){
    var d = new Date();
    if(key === "months"){
    d.setMonth(d.getMonth()-value);
    this.startDate = d;
    var beforeYear = this.startDate.getFullYear().toString().substr(0);
    var beforeMonth = (this.startDate.getMonth() + 1).toString().substr(0);
    var beforeDate = this.startDate.getDate().toString().substr(0);
    this.fromDate = beforeYear + "-" + beforeMonth + "-" + beforeDate;  
    }
    else{  
    d.setDate(d.getDate()-value);
    this.startDate = d;
    var beforeYear = this.startDate.getFullYear().toString().substr(0);
    var beforeMonth = (this.startDate.getMonth() + 1).toString().substr(0);
    var beforeDate = this.startDate.getDate().toString().substr(0);
    this.fromDate = beforeYear + "-" + beforeMonth + "-" + beforeDate;  
    }
  }
  exportExcel() {
    var excelReportData = [];
    this.dentistList.forEach(i => {
      excelReportData.push(
        {
          "Dentist Name": i.name,
          "Number of Cases": i.units,
          "Revenue": i.revenue
        });
    });
    this.excelService.exportAsExcelFile(excelReportData, 'DentistRevenue');
  }
  onChangeLeads($event) {
    if ($event.value === 'bar') {
      this.leadChartType = 'bar';
      this.pieLeadChartLegend = false;
      this.leadsType = this.leadChartType;
      // this.dentistCompanyAddedMonthly();
    }
    else {
      this.leadChartType = "pie";
      this.pieLeadChartLegend = true;
      this.barLeadChartLegend = true;
      this.leadsType = this.leadChartType;
      // this.dentistCompanyAddedMonthly();
    }

  }
  GetperMonthNewLeads(){    
    this.leadChartLabels = [];
    this.ChartDatavalues = [];
    this.leadBarChartData[0].data = [];
    this.reportService.getChartBasedMonthlyLeads(this.SalesRepId).subscribe((data: any) => {
      debugger
      var str = data.label;
      var str_array = str.split(',');
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        this.leadChartLabels.push(str_array[i]);
      }

      this.ChartDatavalues = JSON.parse("[" + data.count + "]");
      this.leadBarChartData[0].data = this.ChartDatavalues;
    }, (err) => { this.toastr.error(err); this.spinner.hide(); })
  }
  getCRMMessagesForDashboard(){
    let ReceiverId = localStorage.getItem('userid');
    this.reportService.getCRMMessagesForDashboard(ReceiverId).subscribe((data: any) => {
      this.crmmessageList=data.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
         findTest.messageId === test.messageId
        )
      );
    }, (err) => { this.toastr.error(err); this.spinner.hide(); })
  }
  downloadUploadedFiles(item) {
    //Download Service here
    let object = {
      messageId: item.messageId
    };
    this.messageService
      .GetMessageUploadedFiles(object)
      .subscribe((data: any) => {
        if (data != null) {
          for (let a = 0; a < data.length; a++) {
            const linkSource = "data:application/pdf;base64," + data[a].file;
            const downloadLink = document.createElement("a");
            const fileName = data[a].fileName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        } else {
          this.toastr.info("File Path Not Found");
        }
      });
  }

}
