import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { CICService } from "./cic.service";
import { ExcelService } from "../share/excel.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ChartOptions, ChartType } from 'chart.js';
import { draw, generate } from 'patternomaly';
import { CaseStatus } from '../models/caseStatus';
import { CaseType } from '../models/caseType';
import { CasesService } from '../cases/cases.service';
import { ReminderDate, TaskStatus, TaskStatusLabelMapping } from "../enum/statusEnum";
import { formatDate, Location } from "@angular/common";
import { OrderRow } from 'src/app/models/orderRow';
import { ManageLeadsService } from "../CRM/manage-leads/manage-leads.service";

@Component({
  selector: "app-cic",
  templateUrl: "./cic.component.html",
  styleUrls: ["./cic.component.css"],
})
export class CICComponent implements OnInit {
  ActivityTypeList: any = [];
  Activity1Id: any = -1;
  Activity2Id: any = -1;
  Activity3Id: any = -1;
  Activity4Id: any = -1;
  IsReminder1:boolean = false;
  IsReminder2:boolean = false;
  IsReminder3:boolean = false;
  IsReminder4:boolean = false;
  Countryflagurl: any;
  RoleName: any;
  compdealerid: any = 0;
  isprodusr: boolean = false;
  UserId: any;
  DealerId: any;
  CompanyId: any;
  TopTwoFiftyDentistList: any = [];
  FilterTopTwoFiftyDentistList: any = [];
  CurrentDate: any = new Date();
  CurrentYear: any;
  sortBy: string = '';
  reverse: boolean = false;
  filterText: any = "";
  FilterBy: any = 1;
  NoRecord: boolean = false;
  DentistID: any;
  @ViewChild('template') template: TemplateRef<any>;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  ClientDetail: any;
  // monthly revenue chart
  ChartDatavalues: any = [];
  revenueChartLabels: any = [];
  revenueBarChartData: any = [
    {
      data: [], label: "Revenue"
    }
  ];
  revenueChartType: any = 'bar';
  pieLeadChartLegend: any = true;
  barRevenueChartLegend: any = false;
  revenueType: ChartType = this.revenueChartType;
  barChartColors: Array<any> = [{
    backgroundColor: ['rgba(135,206,250,1)', 'rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)', 'rgba(148,159,177,1)', '#003f5c', '#003f5c', 'purple', 'purple', 'green', 'green', 'red', 'red', '#98C227', '#98C227', 'orange', 'orange', 'cyan', 'cyan', '#bc5090', '#bc5090', '#58508d', '#58508d'],
    borderColor: ['rgba(135,206,250,1)', 'rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)', 'rgba(148,159,177,1)', '#003f5c', '#003f5c', 'purple', 'purple', 'green', 'green', 'red', 'red', '#98C227', '#98C227', 'orange', 'orange', 'cyan', 'cyan', '#bc5090', '#bc5090', '#58508d', '#58508d']
  }];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  }
  cYrTtlRev: any;
  cYrWorth: any;
  cYrOpportunity: any;
  // Pie charts
  piechartType: string = 'doughnut';
  piechartLabels: Array<string> = [];
  piechartData: Array<number> = [];
  piechartColors: Array<any> = [{
    backgroundColor: ['#ff0090', '#00bbff', '#ffae00']
  }];
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontSize: 15,
        fontColor: 'black',
      }
    }
  }
  // each product
  EachProductList: any = [];
  NoEachProduct: boolean = false;
  ttlPreYrBought: any = 0;
  ttlCurYrBought: any = 0;
  // 4 boxes communication
  CommentId: any = 0;
  CICCommentCondition: any = 0;
  LogComment: any;
  EcoComment: any;
  ProdComment: any;
  SalesComment: any;
  PostComment: any;
  CommentType: any;
  LogisticCommentList: any = [];
  NoLogisticComment: boolean = false;
  EconomyCommentList: any = [];
  NoEconomyComment: boolean = false;
  ProductionCommentList: any = [];
  NoProductionComment: boolean = false;
  SalesCommentList: any = [];
  NoSalesComment: boolean = false;
  // profile note
  ProfileNote: any;
  IsProfileNoteEdit: boolean = false;
  // feedback
  FeedbackList: any = [];
  FeedbackRating: any = 0;
  NoFeedback: boolean = false;
  NoFeedbackRating: boolean = false;
  ratingChartDatavalues: any = [];
  ratingChartLabels: any = [];
  ratingBarChartData: any = [
    {
      data: [], label: "Rating"
    }
  ];
  ratingChartType: any = 'bar';
  barRatingChartLegend: any = false;
  ratingType: ChartType = this.revenueChartType;
  ratingBarChartColors: Array<any> = [{
    backgroundColor: ['#008000', '#40E0D0', '#9FE2BF', '#DFFF00', '#FF7F50'],
    borderColor: ['#008000', '#40E0D0', '#9FE2BF', '#DFFF00', '#FF7F50']
  }];
  ratingBarChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  }
  stars: number[] = [1, 2, 3, 4, 5];
  //openMouth: any = 'https://www.webfx.com/tools/emoji-cheat-sheet/graphics/emojis/open_mouth.png';
  // smirk: any = 'https://www.webfx.com/tools/emoji-cheat-sheet/graphics/emojis/smirk.png';
  // grinning: any = 'https://www.webfx.com/tools/emoji-cheat-sheet/graphics/emojis/grinning.png';
  //smile: any = 'https://www.webfx.com/tools/emoji-cheat-sheet/graphics/emojis/smile.png';
  //smiley: any = 'https://www.webfx.com/tools/emoji-cheat-sheet/graphics/emojis/smiley.png';
  countVhappy: any = 0;
  countHappy: any = 0;
  countNormal: any = 0;
  countSad: any = 0;
  countUhappy: any = 0;
  // cases
  NoCases: boolean = false;
  ongoing: boolean = false;
  done: boolean = false;
  caseStatus: any;
  CaseList: any = [];
  rac: boolean[] = [];
  caseTypeName = [];
  caseType = 1;
  ShowCase: any;
  // task
  taskType: string = 'add';
  NoTask: boolean = false;
  CICTaskList: any = [];
  cictask: any = new CICTask();
  statustaskLabelMapping = TaskStatusLabelMapping;
  statustask = Object.values(TaskStatus).filter(value => typeof value === 'number');
  file: any = [];
  fileName: any;
  fileType: any;
  base64Data: any;
  fileId: any;
  fileAttachment: any = [];
  ReminderDate: any;
  DateForReminder: any;
  ReminderDay = ReminderDate.reminderday;
  //task comment
  //NoTaskComment: boolean = false;
  // amount of remakes
  RemakesList: any = [];
  NoRemakes: boolean = false;
  ttlRemakesQuantity: any = 0;
  hideme = [];
  Index: any;
  orderRowArray: Array<OrderRow> = [];
  totalCost = [];
  supportingOrPontic = [];
  materialFound: boolean[] = [];
  attachmentTaskId: any;
  deletemodalRef: BsModalRef;

  constructor(
    private toastr: ToastrService,
    private service: CICService,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    private modalService: BsModalService,
    private caseService: CasesService,
    private location: Location,
    private manageleadService: ManageLeadsService
  ) {
    this.Countryflagurl = this.service.CountryFlagUrl;
    this.cictask.status = 1;
    let dt = new Date();
    dt.setDate(dt.getDate() + this.ReminderDay);
    this.ReminderDate = formatDate(dt, 'yyyy-MM-dd', 'en_US');
    let dt1 = new Date();
    dt1.setDate(dt1.getDate() - this.ReminderDay);
    this.DateForReminder = formatDate(dt1, 'yyyy-MM-dd', 'en_us');

    this.location.subscribe(location => {
      // ...close popup
      if (window.location.hash != "#cicmodal") {
        this.modalRef.hide();
        window.location.hash = "";
      }
    });
  }

  ngOnInit() {
    this.CurrentYear = this.CurrentDate.getFullYear();
    this.RoleName = localStorage.getItem("RoleName");
    this.UserId = localStorage.getItem("userid");
    this.DealerId = localStorage.getItem("DealerId");
    this.CompanyId = localStorage.getItem("CompanyId");
    this.ShowCase = localStorage.getItem('ShowCase');
    if (this.RoleName == "PRODUCTION_ADMIN" || this.RoleName == "PRODUCTION_USER") {
      this.compdealerid = this.CompanyId;
      this.isprodusr = true;
    }
    else {
      this.compdealerid = this.DealerId;
      this.isprodusr = false;
    }
    this.hideme = [];
    this.GetTopTwoFiftyDentist();
    this.GetActivityType();
  }

  GetTopTwoFiftyDentist() {
    this.spinner.show();
    this.TopTwoFiftyDentistList = [];
    this.FilterTopTwoFiftyDentistList = [];
    this.service.GetTopTwoFiftyDentist(this.DealerId, this.CompanyId).subscribe((data: any) => {
      // data.curYrTopTwoFiftyDen.forEach(ele => {
      //   if (ele.worth == null) {
      //     ele.worth = 0;
      //     ele.opportunity = ele.worth - ele.totalRevenue;
      //   } else {
      //     ele.opportunity = ele.worth - ele.totalRevenue;
      //   }
      //   this.TopTwoFiftyDentistList.push(ele);
      //   this.FilterTopTwoFiftyDentistList.push(ele);
      //   let data1 = data.preYrTopTwoFiftyDen.filter(s => s.dentistCompanyId == ele.dentistCompanyId);
      //   if (data1 != null && data1.length != 0) {
      //     if (data1[0].worth == null) {
      //       data1[0].worth = 0;
      //       data1[0].opportunity = data1[0].worth - data1[0].totalRevenue;
      //     } else {
      //       data1[0].opportunity = data1[0].worth - data1[0].totalRevenue;
      //     }
      //     this.TopTwoFiftyDentistList.push(data1[0]);
      //     this.FilterTopTwoFiftyDentistList.push(data1[0]);
      //   }
      //   let data2 = data.prePreYrTopTwoFiftyDen.filter(f => f.dentistCompanyId == ele.dentistCompanyId);
      //   if (data2 != null && data2.length != 0) {
      //     if (data2[0].worth == null) {
      //       data2[0].worth = 0;
      //       data2[0].opportunity = data2[0].worth - data2[0].totalRevenue;
      //     } else {
      //       data2[0].opportunity = data2[0].worth - data2[0].totalRevenue;
      //     }
      //     this.TopTwoFiftyDentistList.push(data2[0]);
      //     this.FilterTopTwoFiftyDentistList.push(data2[0]);
      //   }
      // });

      if(data.curYrTopTwoFiftyDen.length > 0){
        this.CurrentYear = this.CurrentYear;
        data.curYrTopTwoFiftyDen.forEach(ele => {
          if (ele.worth == null) {
            ele.worth = 0;
            ele.opportunity = ele.worth - ele.totalRevenue;
          } else {
            ele.opportunity = ele.worth - ele.totalRevenue;
          }
          this.TopTwoFiftyDentistList.push(ele);
          this.FilterTopTwoFiftyDentistList.push(ele);
          let data1 = data.preYrTopTwoFiftyDen.filter(s => s.dentistCompanyId == ele.dentistCompanyId);
          if (data1 != null && data1.length != 0) {
            if (data1[0].worth == null) {
              data1[0].worth = 0;
              data1[0].opportunity = data1[0].worth - data1[0].totalRevenue;
            } else {
              data1[0].opportunity = data1[0].worth - data1[0].totalRevenue;
            }
            this.TopTwoFiftyDentistList.push(data1[0]);
            this.FilterTopTwoFiftyDentistList.push(data1[0]);
          }
          let data2 = data.prePreYrTopTwoFiftyDen.filter(f => f.dentistCompanyId == ele.dentistCompanyId);
          if (data2 != null && data2.length != 0) {
            if (data2[0].worth == null) {
              data2[0].worth = 0;
              data2[0].opportunity = data2[0].worth - data2[0].totalRevenue;
            } else {
              data2[0].opportunity = data2[0].worth - data2[0].totalRevenue;
            }
            this.TopTwoFiftyDentistList.push(data2[0]);
            this.FilterTopTwoFiftyDentistList.push(data2[0]);
          }
        });
      } else {
        this.CurrentYear = this.CurrentYear - 1;
      data.preYrTopTwoFiftyDen.forEach(ele => {
        if (ele.worth == null) {
          ele.worth = 0;
          ele.opportunity = ele.worth - ele.totalRevenue;
        } else {
          ele.opportunity = ele.worth - ele.totalRevenue;
        }
        this.TopTwoFiftyDentistList.push(ele);
        this.FilterTopTwoFiftyDentistList.push(ele);
        let data2 = data.prePreYrTopTwoFiftyDen.filter(f => f.dentistCompanyId == ele.dentistCompanyId);
        if (data2 != null && data2.length != 0) {
          if (data2[0].worth == null) {
            data2[0].worth = 0;
            data2[0].opportunity = data2[0].worth - data2[0].totalRevenue;
          } else {
            data2[0].opportunity = data2[0].worth - data2[0].totalRevenue;
          }
          this.TopTwoFiftyDentistList.push(data2[0]);
          this.FilterTopTwoFiftyDentistList.push(data2[0]);
        }
      });
      }
      if (this.FilterTopTwoFiftyDentistList == null || this.FilterTopTwoFiftyDentistList.length == 0) {
        this.NoRecord = true;
      } else {
        this.NoRecord = false;
      }
      this.spinner.hide();
    }, (err) => { this.toastr.error(err); this.spinner.hide(); });
  }

  setOrder(value: string) {
    if (this.sortBy === value) {
      this.reverse = !this.reverse;
    }
    this.sortBy = value;
  }

  searchData() {
    if (this.filterText == undefined || this.filterText == null || this.filterText == "") {
      return this.TopTwoFiftyDentistList = this.FilterTopTwoFiftyDentistList;
    }
    else {
      if (this.FilterBy == 1) {
        this.TopTwoFiftyDentistList = this.FilterTopTwoFiftyDentistList.filter((res: any) => {
          return (res.name.toLowerCase().includes(this.filterText.toLowerCase()));
        });
      } else if (this.FilterBy == 2) {
        this.TopTwoFiftyDentistList = this.FilterTopTwoFiftyDentistList.filter((res: any) => {
          if (res.salesRep != null) {
            return (res.salesRep.toLowerCase().includes(this.filterText.toLowerCase()));
          }
        });
      } else {
        this.TopTwoFiftyDentistList = this.FilterTopTwoFiftyDentistList.filter((res: any) => {
          if (res.invoiceReference != null) {
            return (res.invoiceReference.toLowerCase().includes(this.filterText.toLowerCase()));
          }
        });
      }
    }
  }

  exportExcel() {
    var excelReportData = [];
    this.TopTwoFiftyDentistList.forEach(i => {
      if (i.forYear == this.CurrentYear) {
        excelReportData.push(
          {
            "Dentist Name": i.name,
            "Sales Rep": i.salesRep,
            "Invoice Reference": i.invoiceReference,
            "Year": i.forYear,
            "January": i.janRevenue,
            "February": i.febRevenue,
            "March": i.marRevenue,
            "April": i.aprRevenue,
            "May": i.mayRevenue,
            "June": i.junRevenue,
            "July": i.julRevenue,
            "August": i.augRevenue,
            "September": i.sepRevenue,
            "October": i.octRevenue,
            "November": i.novRevenue,
            "December": i.decRevenue,
            "Total": i.totalRevenue,
            "Worth": i.worth,
            "Opportunity": i.opportunity
          });
      } else {
        excelReportData.push(
          {
            "Dentist Name": "",
            "Sales Rep": "",
            "Invoice Reference": "",
            "Year": i.forYear,
            "January": i.janRevenue,
            "February": i.febRevenue,
            "March": i.marRevenue,
            "April": i.aprRevenue,
            "May": i.mayRevenue,
            "June": i.junRevenue,
            "July": i.julRevenue,
            "August": i.augRevenue,
            "September": i.sepRevenue,
            "October": i.octRevenue,
            "November": i.novRevenue,
            "December": i.decRevenue,
            "Total": "",
            "Worth": "",
            "Opportunity": ""
          });
      }
    });
    this.excelService.exportAsExcelFile(excelReportData, 'Client Information Center');
  }

  openPopUp(dentistId, template: TemplateRef<any>) {
    this.revenueChartLabels = [];
    this.ChartDatavalues = [];
    this.revenueBarChartData[0].data = [];
    this.EachProductList = [];
    this.NoEachProduct = false;
    this.ttlPreYrBought = 0;
    this.ttlCurYrBought = 0
    this.FeedbackList = [];
    this.NoFeedback = false;
    this.NoFeedbackRating = false;
    this.ratingChartLabels = [];
    this.ratingChartDatavalues = [];
    this.ratingBarChartData[0].data = [];
    this.DentistID = dentistId;
    this.RemakesList = [];
    this.NoRemakes = false;
    this.ttlRemakesQuantity = 0;
    this.PostComment = "";
    this.LogComment = "";
    this.EcoComment = "";
    this.ProdComment = "";
    this.SalesComment = "";
    this.cancel();
    this.spinner.show();
    this.service.GetClientInfoDetail(dentistId).subscribe((data: any) => {
      this.GetCICComment(0);
      data.clientInfo.FlagSource = this.Countryflagurl + '/' + data.clientInfo.flagFileName + "?" + Math.floor(Math.random() * 100) + 1;
      this.ClientDetail = data.clientInfo;
      if (this.ClientDetail.note == null || this.ClientDetail.note == "") {
        this.ProfileNote = "";
        this.IsProfileNoteEdit = true;
      } else {
        this.ProfileNote = this.ClientDetail.note;
        this.IsProfileNoteEdit = false;
      }
      this.revenueChartLabels = ['Jan ' + (this.CurrentYear - 1), 'Jan ' + this.CurrentYear, 'Feb ' + (this.CurrentYear - 1), 'Feb ' + this.CurrentYear, 'Mar ' + (this.CurrentYear - 1), 'Mar ' + this.CurrentYear, 'Apr ' + (this.CurrentYear - 1), 'Apr ' + this.CurrentYear, 'May ' + (this.CurrentYear - 1), 'May ' + this.CurrentYear, 'Jun ' + (this.CurrentYear - 1), 'Jun ' + this.CurrentYear, 'Jul ' + (this.CurrentYear - 1), 'Jul ' + this.CurrentYear, 'Aug ' + (this.CurrentYear - 1), 'Aug ' + this.CurrentYear, 'Sep ' + (this.CurrentYear - 1), 'Sep ' + this.CurrentYear, 'Oct ' + (this.CurrentYear - 1), 'Oct ' + this.CurrentYear, 'Nov ' + (this.CurrentYear - 1), 'Nov ' + this.CurrentYear, 'Dec ' + (this.CurrentYear - 1), 'Dec ' + this.CurrentYear];
      let currentYrData = this.TopTwoFiftyDentistList.filter(f => f.dentistCompanyId == dentistId && f.forYear == this.CurrentYear);
      let preYrData = this.TopTwoFiftyDentistList.filter(f => f.dentistCompanyId == dentistId && f.forYear == (this.CurrentYear - 1));
      if (preYrData != null && preYrData.length != 0) {
        this.ChartDatavalues = [preYrData[0].janRevenue, currentYrData[0].janRevenue, preYrData[0].febRevenue, currentYrData[0].febRevenue, preYrData[0].marRevenue, currentYrData[0].marRevenue, preYrData[0].aprRevenue, currentYrData[0].aprRevenue, preYrData[0].mayRevenue, currentYrData[0].mayRevenue, preYrData[0].junRevenue, currentYrData[0].junRevenue, preYrData[0].julRevenue, currentYrData[0].julRevenue, preYrData[0].augRevenue, currentYrData[0].augRevenue, preYrData[0].sepRevenue, currentYrData[0].sepRevenue, preYrData[0].octRevenue, currentYrData[0].octRevenue, preYrData[0].novRevenue, currentYrData[0].novRevenue, preYrData[0].decRevenue, currentYrData[0].decRevenue];
      } else {
        this.ChartDatavalues = [0, currentYrData[0].janRevenue, 0, currentYrData[0].febRevenue, 0, currentYrData[0].marRevenue, 0, currentYrData[0].aprRevenue, 0, currentYrData[0].mayRevenue, 0, currentYrData[0].junRevenue, 0, currentYrData[0].julRevenue, 0, currentYrData[0].augRevenue, 0, currentYrData[0].sepRevenue, 0, currentYrData[0].octRevenue, 0, currentYrData[0].novRevenue, 0, currentYrData[0].decRevenue];
      }
      this.cYrTtlRev = currentYrData[0].totalRevenue;
      this.cYrWorth = currentYrData[0].worth;
      this.cYrOpportunity = currentYrData[0].worth - currentYrData[0].totalRevenue;
      this.revenueBarChartData[0].data = this.ChartDatavalues;
      this.piechartLabels = ['Total', 'Worth', 'Opportunity'];
      this.piechartData = [this.cYrTtlRev, this.cYrWorth, this.cYrOpportunity];
      this.EachProductList = data.eachProduct;
      if (this.EachProductList == null || this.EachProductList.length == 0) {
        this.NoEachProduct = true;
      } else {
        this.EachProductList.forEach(element => {
          this.ttlPreYrBought = this.ttlPreYrBought + element.preYear;
          this.ttlCurYrBought = this.ttlCurYrBought + element.year;
        });
        this.NoEachProduct = false;
      }
      if (data.feedBack != null && data.feedBack.length != 0) {
        this.NoFeedback = false;
        this.FeedbackList = data.feedBack;
      } else {
        this.FeedbackList = [];
        this.NoFeedback = true;
      }
      if (data.feedBackRating != null && data.feedBackRating.length != 0) {
        this.NoFeedbackRating = true;
        const rating = data.feedBackRating.reduce((accumulator, obj) => {
          return accumulator + obj.rating;
        }, 0);
        this.FeedbackRating = rating / data.feedBackRating.length;
        this.ratingChartLabels = ['Very happy', 'Happy', 'Normal', 'Sad', 'Unhappy'];
        let veryhappy = data.feedBackRating.filter(f => f.rating == 5);
        if (veryhappy == null || veryhappy.length == 0) {
          this.countVhappy = 0;
          this.ratingChartDatavalues.push(0);
        } else {
          this.countVhappy = veryhappy.length;
          this.ratingChartDatavalues.push(veryhappy.length);
        }
        let happy = data.feedBackRating.filter(f => f.rating == 4);
        if (happy == null || happy.length == 0) {
          this.countHappy = 0;
          this.ratingChartDatavalues.push(0);
        } else {
          this.countHappy = happy.length;
          this.ratingChartDatavalues.push(happy.length);
        }
        let normal = data.feedBackRating.filter(f => f.rating == 3);
        if (normal == null || normal.length == 0) {
          this.countNormal = 0;
          this.ratingChartDatavalues.push(0);
        } else {
          this.countNormal = normal.length;
          this.ratingChartDatavalues.push(normal.length);
        }
        let sad = data.feedBackRating.filter(f => f.rating == 2);
        if (sad == null || sad.length == 0) {
          this.countSad = 0;
          this.ratingChartDatavalues.push(0);
        } else {
          this.countSad = sad.length;
          this.ratingChartDatavalues.push(sad.length);
        }
        let unhappy = data.feedBackRating.filter(f => f.rating == 1);
        if (unhappy == null || unhappy.length == 0) {
          this.countUhappy = 0;
          this.ratingChartDatavalues.push(0);
        } else {
          this.countUhappy = unhappy.length;
          this.ratingChartDatavalues.push(unhappy.length);
        }
        this.ratingBarChartData[0].data = this.ratingChartDatavalues;
      } else {
        this.FeedbackRating = 0;
        this.countVhappy = 0;
        this.countHappy = 0;
        this.countNormal = 0;
        this.countSad = 0;
        this.countUhappy = 0;
        this.NoFeedbackRating = true;
      }
      if (data.quantityOfRemakes != null && data.quantityOfRemakes.length != 0) {
        this.RemakesList = data.quantityOfRemakes;
        this.RemakesList.forEach(element => {
          this.ttlRemakesQuantity = this.ttlRemakesQuantity + element.quantity;
        });
        this.NoRemakes = false;
      } else {
        this.RemakesList = [];
        this.NoRemakes = true;
      }
      this.ongoingCases();
      this.getCICTasksData();
      this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-full' }, this.config))
      window.location.hash = "cicmodal";
      this.spinner.hide();
    }, (err) => { this.toastr.error(err); this.spinner.hide(); });
  }

  close() {
    this.modalRef.hide();
    window.location.hash = "";
  }

  GetCICComment(condition) {
    this.CICCommentCondition = condition;
    this.service.GetCICComment(this.DentistID).subscribe((res: any) => {
      if (res != null && res.length != 0) {
        let LogistData = res.filter(l => l.commentType == 1);
        let EconomyData = res.filter(e => e.commentType == 2);
        let ProdData = res.filter(p => p.commentType == 3);
        let SalesData = res.filter(s => s.commentType == 4);
        if (this.CICCommentCondition == 0) {
          this.LogisticCommentList = [];
          this.EconomyCommentList = [];
          this.ProductionCommentList = [];
          this.SalesCommentList = [];
          if (LogistData != null && LogistData.length != 0) {
            this.LogisticCommentList = LogistData;
          }
          if (EconomyData != null && EconomyData.length != 0) {
            this.EconomyCommentList = EconomyData;
          }
          if (ProdData != null && ProdData.length != 0) {
            this.ProductionCommentList = ProdData;
          }
          if (SalesData != null && SalesData.length != 0) {
            this.SalesCommentList = SalesData;
          }
        } else {
          if (this.CommentType == 1) {
            this.LogisticCommentList = [];
            if (LogistData != null && LogistData.length != 0) {
              this.LogisticCommentList = LogistData;
            }
          }
          if (this.CommentType == 2) {
            this.EconomyCommentList = [];
            if (EconomyData != null && EconomyData.length != 0) {
              this.EconomyCommentList = EconomyData;
            }
          }
          if (this.CommentType == 3) {
            this.ProductionCommentList = [];
            if (ProdData != null && ProdData.length != 0) {
              this.ProductionCommentList = ProdData;
            }
          }
          if (this.CommentType == 4) {
            this.SalesCommentList = [];
            if (SalesData != null && SalesData.length != 0) {
              this.SalesCommentList = SalesData;
            }
          }
        }
      } else {
        this.LogisticCommentList = [];
        this.EconomyCommentList = [];
        this.ProductionCommentList = [];
        this.SalesCommentList = [];
      }
      if (this.LogisticCommentList == null || this.LogisticCommentList.length == 0) {
        this.NoLogisticComment = true;
      } else {
        this.NoLogisticComment = false;
      }
      if (this.EconomyCommentList == null || this.EconomyCommentList.length == 0) {
        this.NoEconomyComment = true;
      } else {
        this.NoEconomyComment = false;
      }
      if (this.ProductionCommentList == null || this.ProductionCommentList.length == 0) {
        this.NoProductionComment = true;
      } else {
        this.NoProductionComment = false;
      }
      if (this.SalesCommentList == null || this.SalesCommentList.length == 0) {
        this.NoSalesComment = true;
      } else {
        this.NoSalesComment = false;
      }
    }, (err) => { this.toastr.error(err); this.spinner.hide(); });
  }

  submitComment(type) {
    let ActId = 0;
    let ReminderYesNo = false;
    
    this.CommentType = type;
    if (this.CommentType == 1) {
      this.PostComment = this.LogComment;
      if(this.Activity1Id == null || this.Activity1Id == -1 || this.Activity1Id == "-1" || this.Activity1Id == undefined){
        this.toastr.warning("Please select activity type !");
        return false;
      }
      else{
        ActId = this.Activity1Id;
        ReminderYesNo = this.IsReminder1;
      }
    }
    if (this.CommentType == 2) {
      this.PostComment = this.EcoComment;
      if(this.Activity2Id == null || this.Activity2Id == -1 || this.Activity2Id == "-1" || this.Activity2Id == undefined){
        this.toastr.warning("Please select activity type !");
        return false;
      }
      else{
        ActId = this.Activity2Id;
        ReminderYesNo = this.IsReminder2;
      }
    }
    if (this.CommentType == 3) {
      this.PostComment = this.ProdComment;
      if(this.Activity3Id == null || this.Activity3Id == -1 || this.Activity3Id == "-1" || this.Activity3Id == undefined){
        this.toastr.warning("Please select activity type !");
        return false;
      }
      else{
        ActId = this.Activity3Id;
        ReminderYesNo = this.IsReminder3;
      }
    }
    if (this.CommentType == 4) {
      this.PostComment = this.SalesComment;
      if(this.Activity4Id == null || this.Activity4Id == -1 || this.Activity4Id == "-1" || this.Activity4Id == undefined){
        this.toastr.warning("Please select activity type !");
        return false;
      }
      else{
        ActId = this.Activity4Id;
        ReminderYesNo = this.IsReminder4;
      }
    }
    if (this.PostComment == "" || this.PostComment == null) {
      this.toastr.warning("Cannot submit empty comment !");
    } else {
      let obj = {
        CommentId: this.CommentId,
        CommentType: this.CommentType,
        PostComment: this.PostComment,
        UserId: this.UserId,
        DentistId: this.DentistID,
        ActivityId: ActId,
        IsReminder: ReminderYesNo
      }
      this.spinner.show()
      this.service.AddandUpdateCICComment(obj).subscribe((data: any) => {
        if (data == true) {
          this.GetCICComment(1);
          if (this.CommentId == 0) {
            if (this.CommentType == 1) {
              this.toastr.success("Logistic comment added successfully");
              this.Activity1Id = -1;
              this.IsReminder1 = false;
            }
            if (this.CommentType == 2) {
              this.toastr.success("Economy comment added successfully");
              this.Activity2Id = -1;
              this.IsReminder2 = false;
            }
            if (this.CommentType == 3) {
              this.toastr.success("Production comment added successfully");
              this.Activity3Id = -1;
              this.IsReminder3 = false;
            }
            if (this.CommentType == 4) {
              this.toastr.success("Sales comment added successfully");
              this.Activity4Id = -1;
              this.IsReminder4 = false;
            }
          } else {
            if (this.CommentType == 1) {
              this.toastr.success("Logistic comment updated successfully");
              this.Activity1Id = -1;
              this.IsReminder1 = false;
            }
            if (this.CommentType == 2) {
              this.toastr.success("Economy comment updated successfully");
              this.Activity2Id = -1;
              this.IsReminder2 = false;
            }
            if (this.CommentType == 3) {
              this.toastr.success("Production comment updated successfully");
              this.Activity3Id = -1;
              this.IsReminder3 = false;
            }
            if (this.CommentType == 4) {
              this.toastr.success("Sales comment updated successfully");
              this.Activity4Id = -1;
              this.IsReminder4 = false;
            }
          }
          this.PostComment = "";
          this.LogComment = "";
          this.EcoComment = "";
          this.ProdComment = "";
          this.SalesComment = "";
        } else {
          this.toastr.warning("Something is wrong please contact to administration");
        }
        (document.getElementById("Sel"+this.CommentType) as HTMLSelectElement).value = "-1";
        //(document.getElementById("Chk"+this.CommentType)).attributes. = "-1";
        this.spinner.hide()
      },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide()
        }
      );
    }
  }

  editProfileNote() {
    this.IsProfileNoteEdit = true;
  }

  submitProfileNote() {
    if (this.ProfileNote == null || this.ProfileNote == "") {
      this.toastr.warning("Cannot submit empty profile note !");
    } else {
      let obj = {
        DentistId: this.DentistID,
        ProfileNote: this.ProfileNote
      }
      this.toastr.show();
      this.service.SaveProfileNote(obj).subscribe((data: any) => {
        if (data == true) {
          this.IsProfileNoteEdit = false;
          this.toastr.success("Profile note saved successfully");
        } else {
          this.toastr.warning("Something is wrong please contact to administration");
        }
        this.spinner.hide()
      },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide()
        });
    }
  }

  ongoingCases() {
    this.ongoing = true;
    this.done = false;
    this.caseStatus = CaseStatus.Open;
    this.GetLastTenCases()
  }

  doneCases() {
    this.done = true;
    this.ongoing = false;
    this.caseStatus = CaseStatus.Closed;
    this.GetLastTenCases()
  }

  GetLastTenCases() {
    this.hideme = [];
    this.NoCases = false;
    this.CaseList = [];
    this.spinner.show();
    this.service.GetLastTenCases(this.DentistID, this.caseStatus, this.compdealerid, this.isprodusr).subscribe((data: any) => {
      if (data != null && data.length != 0) {
        this.NoCases = false;
        this.CaseList = data;
        this.getCaseTypeData();
      } else {
        this.NoCases = true;
        this.CaseList = [];
      }
      console.log("this.CaseList", this.CaseList);
      this.spinner.hide();
    }, (err) => { this.toastr.error(err); this.spinner.hide(); });
  }

  getCaseTypeData() {
    for (var a = 0; a < this.CaseList.length; a++) {
      if (this.CaseList[a].caseType == CaseType.Remake) {
        this.caseTypeName[a] = "Remake Case";
        this.rac[a] = true;
      } else if (this.CaseList[a].caseType == CaseType.Adjustment) {
        this.caseTypeName[a] = "Adjust Case";
        this.rac[a] = true;
      } else if (this.CaseList[a].caseType == CaseType.Continue) {
        this.caseTypeName[a] = "Continue Case";
        this.rac[a] = true;
      }
    }
  }

  downloadWoPdf(item, Filename) {
    let object = {
      'orderId': item.orderId,
      'fileId': item.fileId,
      'fileName': item.workOrderFileName
    }
    this.caseService.GetFile(object).subscribe((data: any) => {
      if (data != null) {
        const linkSource = 'data:application/pdf;base64,' + data;
        const downloadLink = document.createElement("a");
        const fileName = Filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      else {
        this.toastr.info("File Path Not Found");
      }
    });
  }

  caseDetails(orderId) {
    window.open(`cases/casedetailslist/` + orderId + ``)
  }

  shipmentViewDetailsToId(item) {
    window.open('shipments/viewshipments/' + item.shipmentToId);
  }

  shipmentViewDetailsFromId(item) {
    window.open('shipments/viewshipments/' + item.shipmentFromId);
  }

  onChangeStatus($event) {
    this.cictask.status = $event;
  }

  onSubmitCICTask() {
    if (this.cictask.title == null || this.cictask.title == "") {
      this.toastr.error("Title is required");
      return;
    }
    else {
      this.cictask.createdBy = this.UserId;
      this.cictask.dentistId = this.DentistID;
      this.cictask.crmTaskAttachment = [];
      for (let x = 0; x < this.file.length; x++) {
        let obj = {
          "fileName": this.file[x].name.split('.')[0],
          "fileType": this.file[x].fileType,
          "base64Data": this.file[x].base64Data,
        }
        this.cictask.cicTaskAttachment.push(obj);
      }
      this.spinner.show()
      this.service.AddandUpdateCICTasks(this.cictask).subscribe(
        (data: any) => {
          if (data.message == "1") {
            this.cancel();
            this.getCICTasksData();
            this.toastr.success(
              "CIC Task has been added successfully"
            );
          } else if (data.message == "2") {
            this.cancel();
            this.getCICTasksData();
            this.toastr.success(
              "CIC Task has been updated successfully"
            );
          } else if (data.message == "3") {
            this.cancel();
            this.getCICTasksData();
            this.toastr.warning(
              "CIC Task has been created or updated successfully. But email is null or invalid."
            );
          } else if (data.message == "4") {
            this.cancel();
            this.getCICTasksData();
            this.toastr.warning(
              "CIC Task has been created or updated successfully. But email not sent due to network error."
            );
          }
          this.spinner.hide();
        },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide()
        });
    }
  }

  editCICTask(task) {
    this.fileAttachment = [];
    this.cictask.id = task.id;
    this.cictask.title = task.title;
    this.cictask.status = task.status;
    this.cictask.description = task.description;
    this.cictask.isReminder = task.isReminder;
    this.fileAttachment = task.cicTaskAttachment;
    this.taskType = "edit";
  }

  cancel() {
    this.taskType = "add";
    this.cictask.id = 0;
    this.cictask.createdBy = null;
    this.cictask.title = "";
    this.cictask.status = 1;
    this.cictask.description = "";
    this.cictask.isReminder = false;
    this.cictask.cicTaskAttachment = [];
    this.fileAttachment = [];
    this.file = [];
  }

  removecicTaskFile(taskid, fileid, filename, template: TemplateRef<any>) {
    this.attachmentTaskId = taskid;
    this.fileId = fileid;
    this.fileName = filename;
    this.deletemodalRef = this.modalService.show(template);
  }

  decline() {
    this.deletemodalRef.hide();
  }

  confirm() {
    let obj = {
      "taskid": this.attachmentTaskId,
      "fileid": this.fileId,
      "filename": this.fileName
    }
    this.spinner.show()
    this.service.removecicTaskFile(obj).subscribe(
      (data: any) => {
        if (data == 1) {
          const index: number = this.fileAttachment.indexOf(this.fileId);
          this.fileAttachment.splice(index, 1);
          const index2: number = this.cictask.cicTaskAttachment.indexOf(this.fileId);
          this.cictask.cicTaskAttachment.splice(index2, 1);
          this.toastr.success("Task attachment has been deleted Successfully");
        }
        else {
          this.toastr.error("Something is wrong please contact to administration");
        }
        this.spinner.hide()
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide()
      });
    this.deletemodalRef.hide();
  }

  getCICTasksData() {
    this.spinner.show();
    this.CICTaskList = [];
    this.NoTask = false;
    this.service.getCICTasks(this.DentistID, this.UserId).subscribe((data: any) => {
      this.CICTaskList = data.cicTaskList;
      if (this.CICTaskList != null && this.CICTaskList.length != 0) {
        this.CICTaskList.forEach(ele => {
          if (ele.isReminder == true && (this.ReminderDate >= formatDate(ele.updatedOn, 'yyyy-MM-dd', 'en_US') && formatDate(ele.updatedOn, 'yyyy-MM-dd', 'en_US') >= this.DateForReminder)) {
            ele.isActive = true;
          }
        });
      } else {
        this.CICTaskList = [];
        this.NoTask = true;
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err);
      this.spinner.hide();
    });
  }

  downloadTaskAttachment(item) {
    let object = {
      'taskid': item.id
    }
    //Download Service here 
    this.service.GetTaskUploadedFiles(object).subscribe((data: any) => {
      if (data != null) {
        for (let a = 0; a < data.cicTaskAttachment.length; a++) {
          const linkSource = 'data:application/pdf;base64,' + data.cicTaskAttachment[a].file;
          const downloadLink = document.createElement("a");
          const fileName = data.cicTaskAttachment[a].fileName;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
      else {
        this.toastr.info("File Path Not Found");
      }
    })
  }

  SetActivityValue(event, val) {
    switch (val) {
      case 1:
        this.Activity1Id = event.target.value;
        break;
      case 2:
        this.Activity2Id = event.target.value;
        break;
      case 3:
        this.Activity3Id = event.target.value;
        break;
      case 4:
        this.Activity4Id = event.target.value;
        break;
    }
  }

  GetActivityType() {
    this.manageleadService.GetActivityType().subscribe((data: any) => {
      this.ActivityTypeList = data;
    });
  }


  public showOrderRow(index, orderId) {
    this.hideme[index] = !this.hideme[index];
    this.Index = index;
    this.caseService.getCaseRows(orderId).subscribe((data: any) => {
      this.orderRowArray[index] = data;
      this.totalCost[index] = 0;
      for (var i = 0; i < data.length; i++) {
        this.totalCost[index] = this.totalCost[index] + Number(data[i].cost);
        data[i].cost = data[i].cost.toLocaleString(this.CaseList[index].countryCode, { style: 'currency', currency: this.CaseList[index].shortName });
        let checkSuppOrPontic = data[i].toothNumber;
        checkSuppOrPontic = checkSuppOrPontic ? checkSuppOrPontic.slice(0, 2) : '';
        if (data[i].tooth != null) {
          if (checkSuppOrPontic == "t1" || checkSuppOrPontic == "t4") {
            this.supportingOrPontic[i] = 1;
          }
          else if (checkSuppOrPontic == "t2" || checkSuppOrPontic == "t3") {
            this.supportingOrPontic[i] = 2;
          }
        }
        let caseRowTypeFound = data[i].caseRowType;
        if (caseRowTypeFound == 1) {
          this.materialFound[i] = true;
        }
        else if (caseRowTypeFound == 0) {
          this.materialFound[i] = false;
        }
      }
      this.totalCost[index] = this.totalCost[index].toLocaleString(this.CaseList[index].countryCode, { style: 'currency', currency: this.CaseList[index].shortName });
    }, err => { this.toastr.error(err) });
  }

}

export class CICTask {
  id: any = 0;
  dentistId: any;
  title: any;
  status: any;
  description: any;
  createdBy: any;
  filepath: any;
  filename: any;
  cicTaskAttachment: any = [];
  isReminder: boolean = false;
}
