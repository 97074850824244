import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HomeService } from "../home/home.service";
import { PrintService } from "../cases/print.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CasesService } from "../cases/cases.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  // appleDescription:any;
  // limeCounter:any;
  imgpath: any =
    "http://217.16.197.86:8091/Uploads/Users/a5396d2a-655d-4f78-a728-749e49d0b7b4/bighits.png";
  showPopUp = false;
  showManagementTab = false;
  showReportTab = false;
  showSuperAdminCRMTab = false;
  DealerName: any;
  CompanyId: any;
  teethRUsUserId: any;
  RoleName: any;
  notificationlist: any = [];
  dealerList: any = [];
  dealerInfo: any = [];
  ishomeVisiable: any;
  isCaseVisiable: any;
  isProductVisiable: any;
  isShipmentsVisiable: any;
  isTodoVisiable: any;
  isDentistsVisiable: any;
  isCompaniesVisiable: any;
  isManagmentsVisiable: any;
  isShowManagmentsGoToInvoice: any;
  isShowManagmentsManageCountry: any;
  isShowManagmentsGroupMessaging: any;
  isShowManagmentsUserManagment: any;
  isShowReportsVisiable: any;
  //Report subMenu
  isShowReportsQuickYearOverview: any;
  isShowReportsExcelExport: any;
  isShowReportsStatistics: any;

  isOrderPickupVisiable: any;
  userDetails: any = [];
  loginStatus: any;
  FileName: any;
  FirstName: any;
  LastName: any;
  Url: any;
  loginForOnce: any = 0;
  classActive: boolean[] = [];

  CheckDealerId: any;
  CountryId: any;
  Request: any;
  CheckDealerName: string;
  CheckCountryId: any;

  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";
  showCRMTab: any;
  showCRMReportTab = false;
  showCalenderTab = false;
  ProductionFlag: any;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    public printService: PrintService,
    private caseService: CasesService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(28800);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(15);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      console.log(this.idleState);
      this.homeService.Url = null;
      this.homeService.FirstName = null;
      this.homeService.LastName = null;
      localStorage.clear();
      this.caseService.searchCaseBy = undefined;
      this.caseService.searchId = undefined;
      this.caseService.searchField = false;
      localStorage.removeItem("username");

      this.router.navigate(["/login"]);
      // this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);

      //this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
    this.homeService.Url = null;

    //debugger;
    // this.appleDescription="RangaReddy";
    this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
    let prodFlag = this.userDetails.productionFlag;
    if(prodFlag.toUpperCase() == 'I'){
      this.ProductionFlag = 'I';
    } else if(prodFlag.toUpperCase() == 'O'){
      this.ProductionFlag = 'O';
    } else{
      this.ProductionFlag = prodFlag;
    }
    let firstName = this.userDetails.firstName;
    let lastName = this.userDetails.lastName;
    this.FirstName = firstName;
    this.LastName = lastName;

    this.FileName = localStorage.getItem("FileName");

    this.CompanyId = localStorage.getItem("CompanyId");

    this.loginStatus = localStorage.getItem("loginStatus");
    this.teethRUsUserId = localStorage.getItem("userid");
    this.RoleName = localStorage.getItem("RoleName");
    this.ishomeVisiable = localStorage.getItem("ShowHome");
    this.isCaseVisiable = localStorage.getItem("ShowCase");
    this.isProductVisiable = localStorage.getItem("ShowProduct");
    this.isShipmentsVisiable = localStorage.getItem("ShowShipments");
    this.isTodoVisiable = localStorage.getItem("ShowTodo");
    this.isDentistsVisiable = localStorage.getItem("ShowDentists");
    this.isCompaniesVisiable = localStorage.getItem("ShowCompanies");
    this.isManagmentsVisiable = localStorage.getItem("ShowManagments");
    this.isShowManagmentsGoToInvoice = localStorage.getItem(
      "ShowManagmentsGoToInvoice"
    );
    this.isShowManagmentsManageCountry = localStorage.getItem(
      "ShowManagmentsManageCountry"
    );
    this.isShowManagmentsGroupMessaging = localStorage.getItem(
      "ShowManagmentsGroupMessaging"
    );
    this.isShowManagmentsUserManagment = localStorage.getItem(
      "ShowManagmentsUserManagment"
    );
    this.isShowReportsVisiable = localStorage.getItem("ShowReports");
    //report Submenu
    this.isShowReportsQuickYearOverview = localStorage.getItem(
      "ShowQuickYearOverview"
    );
    this.isShowReportsExcelExport = localStorage.getItem(
      "ShowExcelExportReports"
    );
    this.isShowReportsStatistics = localStorage.getItem(
      "ShowStatisticsReports"
    );

    this.isOrderPickupVisiable = localStorage.getItem("ShowOrderPickup");

    if (this.homeService.Url == undefined || this.homeService.Url == null) {
      this.homeService.Url =
        this.homeService.PhotoUrl + this.teethRUsUserId + "/" + this.FileName;
    }
    if (
      this.homeService.FirstName == undefined ||
      this.homeService.FirstName == null
    ) {
      this.homeService.FirstName = this.FirstName;
    }
    if (
      this.homeService.LastName == undefined ||
      this.homeService.LastName == null
    ) {
      this.homeService.LastName = this.LastName;
    }

    this.getDealer();
  }
  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.getNotification();
  }
  getCaseDashborad() {}

  casesClicked() {
    this.spinner.show();
    this.router.navigate(["/cases/dashboard-list-view"]);
  }
  shipmentClicked() {
    this.spinner.show();
    this.router.navigate(["/shipments"]);
  }
  productClicked() {
    this.spinner.show();
    this.router.navigate(["/products"]);
  }
  dentistCompaniesClicked() {
    this.spinner.show();
    this.router.navigate(["/companies/dentistcompanies/dentist"]);
  }
  dentistCases() {
    this.spinner.show();
    this.router.navigate(["/cases/dentist"]);
  }
  dentistLoginCompaniesClicked() {
    //this.spinner.show();
    this.router.navigate([
      `/companies/dentistcompanies/dentist/company-profile/` +
        this.CompanyId +
        `/` +
        "dentist" +
        ``,
    ]);
  }

  companiesClicked() {
    this.spinner.show();
    this.router.navigate(["/companies/all"]);
  }

  logout() {
    this.homeService.Url = null;
    this.homeService.FirstName = null;
    this.homeService.LastName = null;
    localStorage.clear();
    this.caseService.searchCaseBy = undefined;
    this.caseService.searchId = undefined;
    this.caseService.searchField = false;
    localStorage.removeItem("username");

    this.router.navigate(["/login"]);

    // this.toastr.success("Logout Successfully");
  }
  // shipmentClicked(){
  //   this.spinner.show();
  //   this.router.navigate(['/shipments']);
  // }
  changePassword() {
    this.showPopUp = false;
    this.router.navigate(["/changepassword"]);
  }
  updateProfile() {
    this.showPopUp = false;
    this.router.navigate([
      "management/dentAppUsers/updatedentappuser/",
      this.teethRUsUserId,
    ]);
  }
  goToCompany(type) {
    this.router.navigate(["./companies/" + type + ""]);
  }

  getDealer() {
    this.homeService.getDealerList().subscribe((data: any) => {
      this.dealerList = data;

      this.CheckDealerId = localStorage.getItem("DealerId");
      this.CheckDealerName = localStorage.getItem("DealerName");
      this.CheckCountryId = localStorage.getItem("CountryId");
      if (
        this.RoleName === "SUPER_ADMIN" &&
        (this.CheckDealerId == 0 ||
          this.CheckDealerName == null ||
          this.CheckCountryId == 0)
      ) {
        //if (this.RoleName === "SUPER_ADMIN" && this.CheckDealerId == 0){
        localStorage.setItem("DealerId", this.dealerList[0].item1);
        localStorage.setItem("DealerName", this.dealerList[0].item2);
        localStorage.setItem("CountryId", this.dealerList[0].item3);
        this.DealerName = localStorage.getItem("DealerId");
      } else {
        this.DealerName = localStorage.getItem("DealerId");
        // localStorage.setItem('DealerName',this.dealerList[0].item2)
      }
      if (
        this.RoleName == "SUPER_ADMIN" ||
        this.RoleName == "DEALER_ADMIN" ||
        this.RoleName == "DEALER_USER"
      ) {
        this.Request = "Order Pickup Requests";
      } else if (this.RoleName == "DENTIST_USER") {
        this.Request = "Delivery Pickup Requests";
      }

      this.classActive[1] = true;
    });
  }
  onDealerChange($event) {
    var self = this;
    for (let i = 0; i < this.dealerList.length; i++) {
      if (self.dealerList[i].item1 === Number($event)) {
        this.DealerName = self.dealerList[i].item2;
        this.CountryId = self.dealerList[i].item3;
        break;
      }
    }
    this.dealerInfo = $event;

    localStorage.setItem("DealerId", this.dealerInfo);
    localStorage.setItem("DealerName", this.DealerName);
    localStorage.setItem("CountryId", this.CountryId);

    this.DealerName = localStorage.getItem("DealerId");
    this.router.navigate(["/denthome"]);
    //location.reload();
  }

  //Displaing MAnagement Tab

  // displayManagementTab(i){
  //   this.router.navigate(['/management']);
  //   this.showManagementTab =!this.showManagementTab;
  //   this.classColorActive(i);

  // }

  showHideReportTab() {
    this.showReportTab = !this.showReportTab;
    this.showManagementTab = false;
    this.showSuperAdminCRMTab = false;
  }

  showHideManagementTab() {
    this.showManagementTab = !this.showManagementTab;
    this.showReportTab = false;
    this.showSuperAdminCRMTab = false;
  }
  showHideSuperAdminCRMTab() {
    this.showSuperAdminCRMTab = !this.showSuperAdminCRMTab;
    this.showManagementTab = false;
    this.showReportTab = false;
  }

  showHideCRMTab() {
    this.showCRMTab = !this.showCRMTab;
    this.showCRMReportTab=false;
  }
  showHideCRMReportTab() {
    this.showCRMReportTab = !this.showCRMReportTab;
    this.showCRMTab = false;
  }
  showHideCalenderTab() {
    this.showCalenderTab = !this.showCalenderTab;
  }

  classColorActive(i) {
    for (var x = 0; x < 12; x++) {
      this.classActive[x] = false;
    }
    this.classActive[i] = true;
  }

  closeAllTabs() {
    this.showManagementTab = false;
    this.showReportTab = false;
    this.showSuperAdminCRMTab = false;
  }
  getNotification() {
    this.homeService
      .getNotification(this.teethRUsUserId)
      .subscribe((data: any) => {
        console.log(data);
        for (var i = 0; i < data.item1.length; i++) {
          if (
            data.item1[i].photoURL != undefined ||
            data.item1[i].photoURL != null
          )
            data.item1[i].photoURL =
              this.homeService.PhotoUrl +
              data.item1[i].from_TeethRUsUserId +
              "/" +
              data.item1[i].photoURL;
        }
        this.notificationlist = data;
      });
  }

  opencase(item) {
    this.homeService.UpdateNotification(item.id).subscribe((data: any) => {
      this.getNotification();
      if (this.RoleName == "DENTIST_USER") {
        this.router.navigate([
          `cases/dentist/casedetailslist/` + item.order_OrderId + ``,
        ]);
      } else {
        this.router.navigate([
          `cases/casedetailslist/` + item.order_OrderId + ``,
        ]);
      }
    });
  }

  cicClicked(){
    this.router.navigate(["/cic"]);
  }

}