import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { CasesService } from "./cases/cases.service";
import { HomeService } from "./home/home.service";
import { RoleService } from "./role/role.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  private history = [];
  username: any;
  sub: any;
  isLogin: boolean = true;
  previousUrl: any;
  currentUrl: any;
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private homeService: HomeService,
    private caseService: CasesService,
    private route: ActivatedRoute,
    public router: Router,
    public roleService: RoleService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    //   // sets an idle timeout of 5 seconds, for testing purposes.
    //   idle.setIdle(30);
    //   // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    //   idle.setTimeout(30);
    //   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    //   idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    //   idle.onIdleEnd.subscribe(() => {
    //     this.idleState = 'No longer idle.'
    //     console.log(this.idleState);
    //     this.reset();
    //   });
    //   idle.onTimeout.subscribe(() => {
    //     this.idleState = 'Timed out!';
    //     this.timedOut = true;
    //     console.log(this.idleState);
    //     this.homeService.Url = null;
    //             this.homeService.FirstName = null;
    //             this.homeService.LastName = null;
    //             localStorage.clear();
    //             this.caseService.searchCaseBy = undefined;
    //             this.caseService.searchId = undefined;
    //             this.caseService.searchField = false;
    //             localStorage.removeItem('username')
    //             this.router.navigate(['/login']);
    //    // this.router.navigate(['/']);
    //   });
    //   idle.onIdleStart.subscribe(() => {
    //       this.idleState = 'You\'ve gone idle!'
    //       console.log(this.idleState);
    //       //this.childModal.show();
    //   });
    //   idle.onTimeoutWarning.subscribe((countdown) => {
    //     this.idleState = 'You will time out in ' + countdown + ' seconds!'
    //     console.log(this.idleState);
    //   });
    //   // sets the ping interval to 15 seconds
    //   keepalive.interval(15);
    //   keepalive.onPing.subscribe(() => this.lastPing = new Date());
    //   this.reset();
    // }
    // reset() {
    //   this.idle.watch();
    //   this.idleState = 'Started.';
    //   this.timedOut = false;
    // }
  }

  title = "DentApp";

  ngOnInit() {
    const loginStatus = JSON.stringify(localStorage.getItem("userid"));
    if (loginStatus == "null") {
      var url = window.location.href;
      let prevUrl = window.location.pathname;
      if (prevUrl.includes("setpassword")) {
      } else if (prevUrl.includes("reset-password")) {
      } else if (url.includes("forgotpassword")) {
      } else {
        this.router.navigate(["/login"]);
      }
    } else {
      var url = window.location.href;
      let prevUrl = window.location.pathname;

      let PreviousUrl = localStorage.getItem("PreviousUrl");
      if (url.includes("setpassword")) {
      } else if (url.includes("reset-password")) {
      } else if (prevUrl == "/login") {
        let Role = localStorage.getItem("RoleName");
        Role == "DEALER_SALESREP"
          ? this.router.navigate(["/salesrepdashboard"])
          : this.router.navigate(["/denthome"]);
      } else if (url[url.length - 1] != "/") {
        this.router.navigate([prevUrl]);
      } else {
        let Role = localStorage.getItem("RoleName");
        Role == "DEALER_SALESREP"
          ? this.router.navigate(["/salesrepdashboard"])
          : this.router.navigate(["/denthome"]);
      }
    }
  }
}
